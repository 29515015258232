import axios from "axios";
import loader from "./logo-loader.png";
import badge1 from "./badge-1.png";
import badge3 from "./badge-3.png";
import badge2 from "./badge-2.png";

export const X_AUTH_TOKEN = 'x-auth-token';
export const SKILL_COLORS = 'skill-colors';
export const PROFILE_LIST_FIELDS = 'id,score,firstName,lastName,name,user,jobTitle,company,description,address,program300j,visibility,ownerId,createDate,modifyDate,skills,languages'
// export const PROFILE_VIEW_FIELDS = `${PROFILE_LIST_FIELDS},experiences,formations,skills,languages,hobbies`

export const GOLD = process.env.REACT_APP_GLOD || 0.8;
export const BRONZE = process.env.REACT_APP_BRONZE || 0.6
export const MIN_SCORE = process.env.REACT_APP_MIN_SCORE || 40
export const MAX_SCORE = process.env.REACT_APP_MAX_SCORE || 100

export function storeToken(headers) {
    if (headers) {
        let token = headers[X_AUTH_TOKEN] ? headers[X_AUTH_TOKEN] : headers.get(X_AUTH_TOKEN);
        localStorage.setItem(X_AUTH_TOKEN, token);
    }
}

export function removeToken() {
    localStorage.removeItem(X_AUTH_TOKEN);
}

export function storeObject(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function getObject(key) {
    return JSON.parse(localStorage.getItem(key))
}

export function getToken() {
    return localStorage.getItem(X_AUTH_TOKEN);
}

export function get(path, headers = {}) {
    const token = getToken();
    return axios.get(path, {
        headers: {
            'Authorization': token ? `Bearer ${token}` : '',
            ...headers
        }
    });
}

export function post(path, data, headers = {}) {
    let token = getToken();
    return axios.post(path, data, {
        headers: {
            'Authorization': token ? `Bearer ${token}` : '',
            ...headers
        }
    });
}

export function put(path, data, headers = {}) {
    let token = getToken();
    return axios.put(path, data, {
        headers: {
            'Authorization': token ? `Bearer ${token}` : '',
            ...headers
        }
    });
}

export function remove(path, headers = {}) {
    let token = getToken();
    return axios.delete(path, {
        headers: {
            'Authorization': token ? `Bearer ${token}` : '',
            ...headers
        }
    });
}

export function queryParams(params = {}) {
    let result = '';
    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            if (result === '') {
                result = `${key}=${params[key]}`
            } else {
                result = `${result}&${key}=${params[key]}`
            }
        }
    }
    return result;
}

export function parseQueryString() {
    let queryString = window.location.search;
    queryString = decodeURI(queryString.substring(1));

    var params = {},
        queries,
        temp,
        i,
        l;

    // Split into key/value pairs
    queries = queryString.split("&");

    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
        temp = queries[i].split("=");
        params[temp[0]] = temp[1];
    }

    return params;
}

export const refreshTokenSetup = (res) => {
    // Timing to renew access token
    let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

    const refreshToken = async () => {
        if (typeof res.reloadAuthResponse === "function") {
            const newAuthRes = await res.reloadAuthResponse();
            refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
            // saveUserToken(newAuthRes.access_token);  <-- save new token
            localStorage.setItem("authToken", newAuthRes.id_token);

            // Setup the other timer after the first one
            setTimeout(refreshToken, refreshTiming);
        } else {
            window.location.href = '/'
        }
    };

    // Setup first refresh timer
    setTimeout(refreshToken, refreshTiming);
};

export const has = (roles, roleList) => {
    if (!roles || roles.length === 0) return false;
    let myRoles = roles.reduce((cumul, curr) => {
        return `${cumul}${curr.role};`;
    }, ';');
    if (typeof roleList === 'string') return myRoles.indexOf(`;${roleList};`) >= 0;
    return (roleList || []).reduce((yes, role) => yes || myRoles.indexOf(`;${role};`) >= 0, false);
}

export const goToTop = ($) => {
    $('.js-go-to').trigger('click');
};

export const overlay = ($, loading) => {
    if (loading) {
        $.LoadingOverlay("show", {
            image: loader,
            progress: true
        })
    } else {
        $.LoadingOverlay("hide")
    }
}

export const goTo = (history, path) => {
    return (e) => {
        e.preventDefault();
        history.push(path);
    }
}

export const rgbToHex = function (rgb) {
    let hex = Number(rgb).toString(16);
    if (hex.length < 2) {
        hex = "0" + hex;
    }
    return hex;
};

export const fullColorHex = function ({r, g, b}) {
    return `#${rgbToHex(r)}${rgbToHex(g)}${rgbToHex(b)}`;
};

export const randomColor = () => {
    let r = Math.ceil(255 * Math.random());
    let g = Math.ceil(255 * Math.random());
    let b = Math.ceil(255 * Math.random());
    let bgColor = {r, g, b};
    return fullColorHex(bgColor);
}

export const generateColor = (count) => {

    let colors = [];
    let alpha = 0.7;
    for (let i = 0; i < count; i++) {
        let r = Math.ceil(255 * Math.random());
        let g = Math.ceil(255 * Math.random());
        let b = Math.ceil(255 * Math.random());
        let bgColor = {r, g, b};
        let fgColor = {
            r: Math.ceil(r * alpha),
            g: Math.ceil(g * alpha),
            b: Math.ceil(b * alpha)
        };
        colors.push({bgColor: fullColorHex(bgColor), fgColor: fullColorHex(fgColor)});
    }
    return colors;
}

export const getSkillColors = (force = false) => {
    let colors = getObject(SKILL_COLORS);
    if (colors && !force) {
        return colors;
    }
    colors = generateColor(20);
    storeObject(SKILL_COLORS, colors);
    return colors;
}

// ============== JS Handling =========== //
export const initHeader = ($) => {
    // initialization of header
    $.HSCore.components.HSHeader.init($('#js-header'));
    $.HSCore.helpers.HSHamburgers.init('.hamburger');

    // initialization of HSMegaMenu component
    $('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: $('.container'),
        breakpoint: 991
    });
}

export const initGoTo = ($) => {
    $.HSCore.components.HSGoTo.init('.js-go-to');
}

export const initCharts = ($) => {
    $.HSCore.components.HSChartPie.init('.js-pie');
    $.HSCore.components.HSScrollBar.init($('.js-scrollbar'));
    setTimeout(function () { // important in this case
        $.HSCore.components.HSProgressBar.init('.js-hr-progress-bar', {
            direction: 'horizontal',
            indicatorSelector: '.js-hr-progress-bar-indicator'
        });
    }, 1);
}

export const initAnimation = ($) => {
    // initialization of header's height equal offset
    $.HSCore.helpers.HSHeightCalc.init();

    // initialization of scroll animation
    $.HSCore.components.HSOnScrollAnimation.init('[data-animation]');
    // initialization of video on background
    //$.HSCore.helpers.HSBgVideo.init('.js-bg-video');
    // initialization of popups with media
    $.HSCore.components.HSPopup.init('.js-fancybox-media', {
        helpers: {
            media: {},
            overlay: {
                css: {
                    'background': 'rgba(0, 0, 0, .8)'
                }
            }
        }
    });
};

export const showModal = (config = {}) => {
    const {Custombox} = window;
    const modalConfig = {
        bounds: 100,
        debounce: 50,
        overlayOpacity: .48,
        overlayColor: '#000000',
        speed: 400,
        type: 'onscroll', // onscroll, beforeunload, hashlink, ontarget, aftersometime
        effect: 'door',
        modalIsClosingByEsc: true,
        modalIsClosingByOverlay: true,
        onOpen: function () {
            Custombox.modal.closeAll();
        },
        onClose: function () {
        },
        onComplete: function () {
        },
        ...config
    };

    const modalBox = new Custombox.modal(
        {
            content: {
                target: modalConfig.target,
                effect: modalConfig.effect,
                close: modalConfig.modalIsClosingByEsc,
                onOpen: modalConfig.onOpen,
                onClose: modalConfig.onClose,
                onComplete: modalConfig.onComplete
            },
            overlay: {
                color: modalConfig.overlayColor,
                opacity: modalConfig.overlayOpacity,
                close: modalConfig.modalIsClosingByOverlay,
                speedIn: modalConfig.speed,
                speedOut: modalConfig.speed
            }
        }
    );
    modalBox.open();
}

export const cumulator = (cumul, curr) => {
    return cumul + (curr.currency === 'eur' ? curr.amount : curr.amount / 6.55957)
};

export const getScoreColor = (score) => {
    let color = fullColorHex({r:221, g:220, b:218});
    if (score > GOLD) {
        color = fullColorHex({r:251, g:234, b:153});
    } else if (score > BRONZE) {
        color = fullColorHex({r:249, g:207, b:147});
    }
    return color;
}

export const getScoreImage = (score) => {
    let badge = badge1;
    if (score >= GOLD) {
        badge = badge3;
    } else if (score >= BRONZE) {
        badge = badge2;
    }
    return badge;
}

export const fontSizeComputer = (x, m, M) => {
    const a = MIN_SCORE;
    const b = MAX_SCORE;
    return m + (M - m) * (x - a) / (b - a)
}

export const getCountries = () => {
    return [
        {name: 'Afghanistan', code: 'AF'},
        {name: 'Åland Islands', code: 'AX'},
        {name: 'Albania', code: 'AL'},
        {name: 'Algeria', code: 'DZ'},
        {name: 'American Samoa', code: 'AS'},
        {name: 'AndorrA', code: 'AD'},
        {name: 'Angola', code: 'AO'},
        {name: 'Anguilla', code: 'AI'},
        {name: 'Antarctica', code: 'AQ'},
        {name: 'Antigua and Barbuda', code: 'AG'},
        {name: 'Argentina', code: 'AR'},
        {name: 'Armenia', code: 'AM'},
        {name: 'Aruba', code: 'AW'},
        {name: 'Australia', code: 'AU'},
        {name: 'Austria', code: 'AT'},
        {name: 'Azerbaijan', code: 'AZ'},
        {name: 'Bahamas', code: 'BS'},
        {name: 'Bahrain', code: 'BH'},
        {name: 'Bangladesh', code: 'BD'},
        {name: 'Barbados', code: 'BB'},
        {name: 'Belarus', code: 'BY'},
        {name: 'Belgium', code: 'BE'},
        {name: 'Belize', code: 'BZ'},
        {name: 'Benin', code: 'BJ'},
        {name: 'Bermuda', code: 'BM'},
        {name: 'Bhutan', code: 'BT'},
        {name: 'Bolivia', code: 'BO'},
        {name: 'Bosnia and Herzegovina', code: 'BA'},
        {name: 'Botswana', code: 'BW'},
        {name: 'Bouvet Island', code: 'BV'},
        {name: 'Brazil', code: 'BR'},
        {name: 'British Indian Ocean Territory', code: 'IO'},
        {name: 'Brunei Darussalam', code: 'BN'},
        {name: 'Bulgaria', code: 'BG'},
        {name: 'Burkina Faso', code: 'BF'},
        {name: 'Burundi', code: 'BI'},
        {name: 'Cambodia', code: 'KH'},
        {name: 'Cameroon', code: 'CM'},
        {name: 'Canada', code: 'CA'},
        {name: 'Cape Verde', code: 'CV'},
        {name: 'Cayman Islands', code: 'KY'},
        {name: 'Central African Republic', code: 'CF'},
        {name: 'Chad', code: 'TD'},
        {name: 'Chile', code: 'CL'},
        {name: 'China', code: 'CN'},
        {name: 'Christmas Island', code: 'CX'},
        {name: 'Cocos (Keeling) Islands', code: 'CC'},
        {name: 'Colombia', code: 'CO'},
        {name: 'Comoros', code: 'KM'},
        {name: 'Congo', code: 'CG'},
        {name: 'Congo, The Democratic Republic of the', code: 'CD'},
        {name: 'Cook Islands', code: 'CK'},
        {name: 'Costa Rica', code: 'CR'},
        {name: 'Cote D\'Ivoire', code: 'CI'},
        {name: 'Croatia', code: 'HR'},
        {name: 'Cuba', code: 'CU'},
        {name: 'Cyprus', code: 'CY'},
        {name: 'Czech Republic', code: 'CZ'},
        {name: 'Denmark', code: 'DK'},
        {name: 'Djibouti', code: 'DJ'},
        {name: 'Dominica', code: 'DM'},
        {name: 'Dominican Republic', code: 'DO'},
        {name: 'Ecuador', code: 'EC'},
        {name: 'Egypt', code: 'EG'},
        {name: 'El Salvador', code: 'SV'},
        {name: 'Equatorial Guinea', code: 'GQ'},
        {name: 'Eritrea', code: 'ER'},
        {name: 'Estonia', code: 'EE'},
        {name: 'Ethiopia', code: 'ET'},
        {name: 'Falkland Islands (Malvinas)', code: 'FK'},
        {name: 'Faroe Islands', code: 'FO'},
        {name: 'Fiji', code: 'FJ'},
        {name: 'Finland', code: 'FI'},
        {name: 'France', code: 'FR'},
        {name: 'French Guiana', code: 'GF'},
        {name: 'French Polynesia', code: 'PF'},
        {name: 'French Southern Territories', code: 'TF'},
        {name: 'Gabon', code: 'GA'},
        {name: 'Gambia', code: 'GM'},
        {name: 'Georgia', code: 'GE'},
        {name: 'Germany', code: 'DE'},
        {name: 'Ghana', code: 'GH'},
        {name: 'Gibraltar', code: 'GI'},
        {name: 'Greece', code: 'GR'},
        {name: 'Greenland', code: 'GL'},
        {name: 'Grenada', code: 'GD'},
        {name: 'Guadeloupe', code: 'GP'},
        {name: 'Guam', code: 'GU'},
        {name: 'Guatemala', code: 'GT'},
        {name: 'Guernsey', code: 'GG'},
        {name: 'Guinea', code: 'GN'},
        {name: 'Guinea-Bissau', code: 'GW'},
        {name: 'Guyana', code: 'GY'},
        {name: 'Haiti', code: 'HT'},
        {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
        {name: 'Holy See (Vatican City State)', code: 'VA'},
        {name: 'Honduras', code: 'HN'},
        {name: 'Hong Kong', code: 'HK'},
        {name: 'Hungary', code: 'HU'},
        {name: 'Iceland', code: 'IS'},
        {name: 'India', code: 'IN'},
        {name: 'Indonesia', code: 'ID'},
        {name: 'Iran, Islamic Republic Of', code: 'IR'},
        {name: 'Iraq', code: 'IQ'},
        {name: 'Ireland', code: 'IE'},
        {name: 'Isle of Man', code: 'IM'},
        {name: 'Israel', code: 'IL'},
        {name: 'Italy', code: 'IT'},
        {name: 'Jamaica', code: 'JM'},
        {name: 'Japan', code: 'JP'},
        {name: 'Jersey', code: 'JE'},
        {name: 'Jordan', code: 'JO'},
        {name: 'Kazakhstan', code: 'KZ'},
        {name: 'Kenya', code: 'KE'},
        {name: 'Kiribati', code: 'KI'},
        {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
        {name: 'Korea, Republic of', code: 'KR'},
        {name: 'Kuwait', code: 'KW'},
        {name: 'Kyrgyzstan', code: 'KG'},
        {name: 'Lao People\'S Democratic Republic', code: 'LA'},
        {name: 'Latvia', code: 'LV'},
        {name: 'Lebanon', code: 'LB'},
        {name: 'Lesotho', code: 'LS'},
        {name: 'Liberia', code: 'LR'},
        {name: 'Libyan Arab Jamahiriya', code: 'LY'},
        {name: 'Liechtenstein', code: 'LI'},
        {name: 'Lithuania', code: 'LT'},
        {name: 'Luxembourg', code: 'LU'},
        {name: 'Macao', code: 'MO'},
        {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
        {name: 'Madagascar', code: 'MG'},
        {name: 'Malawi', code: 'MW'},
        {name: 'Malaysia', code: 'MY'},
        {name: 'Maldives', code: 'MV'},
        {name: 'Mali', code: 'ML'},
        {name: 'Malta', code: 'MT'},
        {name: 'Marshall Islands', code: 'MH'},
        {name: 'Martinique', code: 'MQ'},
        {name: 'Mauritania', code: 'MR'},
        {name: 'Mauritius', code: 'MU'},
        {name: 'Mayotte', code: 'YT'},
        {name: 'Mexico', code: 'MX'},
        {name: 'Micronesia, Federated States of', code: 'FM'},
        {name: 'Moldova, Republic of', code: 'MD'},
        {name: 'Monaco', code: 'MC'},
        {name: 'Mongolia', code: 'MN'},
        {name: 'Montserrat', code: 'MS'},
        {name: 'Morocco', code: 'MA'},
        {name: 'Mozambique', code: 'MZ'},
        {name: 'Myanmar', code: 'MM'},
        {name: 'Namibia', code: 'NA'},
        {name: 'Nauru', code: 'NR'},
        {name: 'Nepal', code: 'NP'},
        {name: 'Netherlands', code: 'NL'},
        {name: 'Netherlands Antilles', code: 'AN'},
        {name: 'New Caledonia', code: 'NC'},
        {name: 'New Zealand', code: 'NZ'},
        {name: 'Nicaragua', code: 'NI'},
        {name: 'Niger', code: 'NE'},
        {name: 'Nigeria', code: 'NG'},
        {name: 'Niue', code: 'NU'},
        {name: 'Norfolk Island', code: 'NF'},
        {name: 'Northern Mariana Islands', code: 'MP'},
        {name: 'Norway', code: 'NO'},
        {name: 'Oman', code: 'OM'},
        {name: 'Pakistan', code: 'PK'},
        {name: 'Palau', code: 'PW'},
        {name: 'Palestinian Territory, Occupied', code: 'PS'},
        {name: 'Panama', code: 'PA'},
        {name: 'Papua New Guinea', code: 'PG'},
        {name: 'Paraguay', code: 'PY'},
        {name: 'Peru', code: 'PE'},
        {name: 'Philippines', code: 'PH'},
        {name: 'Pitcairn', code: 'PN'},
        {name: 'Poland', code: 'PL'},
        {name: 'Portugal', code: 'PT'},
        {name: 'Puerto Rico', code: 'PR'},
        {name: 'Qatar', code: 'QA'},
        {name: 'Reunion', code: 'RE'},
        {name: 'Romania', code: 'RO'},
        {name: 'Russian Federation', code: 'RU'},
        {name: 'RWANDA', code: 'RW'},
        {name: 'Saint Helena', code: 'SH'},
        {name: 'Saint Kitts and Nevis', code: 'KN'},
        {name: 'Saint Lucia', code: 'LC'},
        {name: 'Saint Pierre and Miquelon', code: 'PM'},
        {name: 'Saint Vincent and the Grenadines', code: 'VC'},
        {name: 'Samoa', code: 'WS'},
        {name: 'San Marino', code: 'SM'},
        {name: 'Sao Tome and Principe', code: 'ST'},
        {name: 'Saudi Arabia', code: 'SA'},
        {name: 'Senegal', code: 'SN'},
        {name: 'Serbia and Montenegro', code: 'CS'},
        {name: 'Seychelles', code: 'SC'},
        {name: 'Sierra Leone', code: 'SL'},
        {name: 'Singapore', code: 'SG'},
        {name: 'Slovakia', code: 'SK'},
        {name: 'Slovenia', code: 'SI'},
        {name: 'Solomon Islands', code: 'SB'},
        {name: 'Somalia', code: 'SO'},
        {name: 'South Africa', code: 'ZA'},
        {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
        {name: 'Spain', code: 'ES'},
        {name: 'Sri Lanka', code: 'LK'},
        {name: 'Sudan', code: 'SD'},
        {name: 'Suriname', code: 'SR'},
        {name: 'Svalbard and Jan Mayen', code: 'SJ'},
        {name: 'Swaziland', code: 'SZ'},
        {name: 'Sweden', code: 'SE'},
        {name: 'Switzerland', code: 'CH'},
        {name: 'Syrian Arab Republic', code: 'SY'},
        {name: 'Taiwan, Province of China', code: 'TW'},
        {name: 'Tajikistan', code: 'TJ'},
        {name: 'Tanzania, United Republic of', code: 'TZ'},
        {name: 'Thailand', code: 'TH'},
        {name: 'Timor-Leste', code: 'TL'},
        {name: 'Togo', code: 'TG'},
        {name: 'Tokelau', code: 'TK'},
        {name: 'Tonga', code: 'TO'},
        {name: 'Trinidad and Tobago', code: 'TT'},
        {name: 'Tunisia', code: 'TN'},
        {name: 'Turkey', code: 'TR'},
        {name: 'Turkmenistan', code: 'TM'},
        {name: 'Turks and Caicos Islands', code: 'TC'},
        {name: 'Tuvalu', code: 'TV'},
        {name: 'Uganda', code: 'UG'},
        {name: 'Ukraine', code: 'UA'},
        {name: 'United Arab Emirates', code: 'AE'},
        {name: 'United Kingdom', code: 'GB'},
        {name: 'United States', code: 'US'},
        {name: 'United States Minor Outlying Islands', code: 'UM'},
        {name: 'Uruguay', code: 'UY'},
        {name: 'Uzbekistan', code: 'UZ'},
        {name: 'Vanuatu', code: 'VU'},
        {name: 'Venezuela', code: 'VE'},
        {name: 'Viet Nam', code: 'VN'},
        {name: 'Virgin Islands, British', code: 'VG'},
        {name: 'Virgin Islands, U.S.', code: 'VI'},
        {name: 'Wallis and Futuna', code: 'WF'},
        {name: 'Western Sahara', code: 'EH'},
        {name: 'Yemen', code: 'YE'},
        {name: 'Zambia', code: 'ZM'},
        {name: 'Zimbabwe', code: 'ZW'}
    ]
}