import React from "react";

export default function Alert(props) {
    return Boolean(props.message) &&
        <div className={`alert alert-dismissible fade show g-bg-${props.success ? 'green' : 'yellow'} rounded-0`}
             role="alert">
            <button
                type="button"
                className="close u-alert-close--light"
                data-dismiss="alert"
                aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
            <div className="media">
                                <span className="d-flex g-mr-10 g-mt-5">
                                    <i className="icon-info g-font-size-25"/>
                                </span>
                <span className="media-body align-self-center">
                                    {props.message}
                                </span>
            </div>
        </div>
}