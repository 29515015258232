import React, {useEffect} from "react";
import {initGoTo} from "../utils";

const {$} = window;

export default function UnifyGoToTop(props) {
    useEffect(() => {
        initGoTo($)
    });
    return (
        <>
            {/*<!-- Go To Top -->*/}
            <a className="js-go-to u-go-to-v1" href="/#"
               data-type="fixed"
               data-position='{
           "bottom": 15,
           "right": 15
         }'
               data-offset-top="300"
               data-compensation="#js-header"
               data-show-effect="zoomIn">
                <i className="hs-icon hs-icon-arrow-top"/>
            </a>
            {/*<!-- End Go To Top -->*/}
        </>
    )
}