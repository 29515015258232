import React, {useEffect, useState} from "react";

import {useHistory, useParams} from 'react-router-dom'

import {cumulator, get, overlay, post} from "../utils";
import * as moment from "moment";
import t from "../i18n/translator";

const cardAmounts = [
    {
        name: 'amount',
        amount: 20,
        currency: '€'
    },
    {
        name: 'amount',
        amount: 30,
        currency: '€'
    },
    {
        name: 'amount',
        amount: 50,
        currency: '€'
    },
    {
        name: 'amount',
        amount: 'xxx',
        currency: '€',
        custom: true
    }
];

const xorAmounts = [
    {
        name: 'amount',
        amount: 15000,
        currency: 'FCFA'
    },
    {
        name: 'amount',
        amount: 20000,
        currency: 'FCFA'
    },
    {
        name: 'amount',
        amount: 'xxx',
        currency: 'FCFA',
        custom: true
    }
]

const services = [
    {
        name: 'service',
        value: 'stripe',
        label: 'CB',
        disabled: process.env.REACT_APP_PAYMENT_DISABLED_STRIPE === 'true'
    },
    {
        name: 'service',
        value: 'samamoney',
        label: 'Sama Money',
        disabled: process.env.REACT_APP_PAYMENT_DISABLED_SAMA_MONEY === 'true'
    },
    {
        name: 'service',
        value: 'orange',
        label: 'O. Money',
        disabled: process.env.REACT_APP_PAYMENT_DISABLED_ORANGE === 'true'
    }
]

const {$} = window;
//const {$, Custombox} = window;

const XOR_DEFAULT_AMOUNT = 15000;
const EUR_DEFAULT_AMOUNT = 30;
export default function PaymentForm(props) {
    const defaultAmount = props.amount || EUR_DEFAULT_AMOUNT;
    const {name} = props
    const [amount, setAmount] = useState(defaultAmount);
    const [selectedAmount, setSelectedAmount] = useState(defaultAmount);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState(null);
    const [session, setSession] = useState({});
    const [currency, setCurrency] = useState('eur');
    const [service, setService] = useState('stripe');

    const amountRender = ({name: itemName, amount: itemAmount, currency: itemCurrency, custom}, key) => {
        return <label key={key}
                      className="u-check">
            <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                   name={itemName}
                   type="radio"
                   checked={selectedAmount === itemAmount}
                   onChange={(e) => {
                       setSelectedAmount(itemAmount);
                       if (custom) {
                           setAmount(0);
                       } else {
                           setAmount(itemAmount);
                       }
                   }}
            />
            <span
                className="btn btn-md btn-block u-btn-outline-lightgray g-color-white--checked g-bg-primary--checked rounded-0">
                {`${itemAmount} ${itemCurrency}`}
            </span>
        </label>
    }

    const serviceRender = ({name, value, label, disabled}, key) => {
        return <label key={key}
                      className="u-check">
            <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                   name={name}
                   type="radio"
                   disabled={disabled === true}
                   checked={service === value}
                   onChange={(e) => {
                       if (value === 'stripe') {
                           setAmount(defaultAmount);
                           setSelectedAmount(defaultAmount);
                           setCurrency('eur');
                       } else if (value === 'orange') {
                           setAmount(XOR_DEFAULT_AMOUNT);
                           setSelectedAmount(XOR_DEFAULT_AMOUNT);
                           setCurrency('XOF');
                       } else {
                           setAmount(XOR_DEFAULT_AMOUNT);
                           setSelectedAmount(XOR_DEFAULT_AMOUNT);
                           setCurrency('XOF');
                       }
                       setService(value);
                   }}
            />
            <span
                className="btn btn-md btn-block u-btn-outline-lightgray g-color-white--checked g-bg-primary--checked rounded-0">
                {label}
            </span>
        </label>
    }

    const getAmounts = () => {
        if (service === 'stripe') {
            return cardAmounts
        }
        return xorAmounts;
    }

    const params = useParams();

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let body = {
            amount: currency === 'eur' ? amount * 100 : amount,
            currency: currency,
            description: `Contribution pour la session ${session.title}`,
            session: {
                id: params.sessionId
            },
            service
        }
        console.log('body', body);
        let path = `/api/payment/${service}`;
        if (props.pool) {
            path = `/api/payment/session/${session.id}`;
        }
        post(path, body, {
            'Content-Type': 'application/json'
        }).then(({data: response}) => {
            setLoading(false);
            if (response.code && response.code / 100 !== 2) {
                setMessage(response.message);
            } else {
                console.log('response', response);
                window.location.href = `${response.payment_url}&redirect=${window.location.href}`;
                //window.location.href = `${response.payment_url}&redirect=${window.location.protocol}//${window.location.host}/${props.pool ? "pool" : "payment"}/${params.sessionId}`;
                //window.open(`${response.payment_url}&redirect=${window.location.protocol}//${window.location.host}/payment/${params.sessionId}`, '_blank');
                setPaymentUrl(response.payment_url);
            }
        })
            .catch(err => {
                setLoading(false);
                setMessage(err.message);
            })
    };

    const loadSession = async () => {
        overlay($, true);
        try {
            let path = `/api/session/${params.sessionId}`;
            if (props.pool) {
                path = `/api/payment/session/${params.sessionId}`;
            }
            let response = await get(path);
            console.log(response);
            setSession(response.data)
        } catch (e) {
            console.error('err', e);
        } finally {
            overlay($, false);
        }
    }

    useEffect(() => {
        //let target = '#complete-payment';
        if (!session?.title && !session?.description) {
            loadSession();
        }
    });

    const history = useHistory();

    const goTo = (path) => {
        return (e) => {
            e.preventDefault();
            history.push(path);
        };
    }

    const getSuccessCardPayments = (session) => {
        return (session.payments || []).filter(pay => pay.success && pay.currency === 'eur');
    }

    const getSuccessOtherPayments = (session) => {
        return (session.payments || []).filter(pay => pay.success && pay.currency !== 'eur');
    }

    const getMyPayments = (session) => {
        return (session.payments || []).filter(pay => pay.ownerId === props.userId && pay.success);
    }
    let cardPayments = []
    let otherPayments = []
    let myCardPayments = [];
    let iParticipate = false;
    let paymentCount = 0;
    if (session) {
        cardPayments = getSuccessCardPayments(session);
        otherPayments = getSuccessOtherPayments(session);
        myCardPayments = getMyPayments(session);
        iParticipate = (myCardPayments || []).length > 0;
        document.title = 'CPD : ' + session.title
        paymentCount = (session.payments || []).filter(pay => pay.success).length;
    }
    return Boolean(session) && <div className="container">
        <div className={"row"}>
            <div className={"col-lg-7"}>
                <div className={"row"}>
                    <div className={"col-md-10"}>
                        <h1 className="g-mb-20">{session?.title}</h1>
                    </div>
                    {props.canEdit && <div className={"col-md-2"}>
                        <button className={"btn btn-primary"}
                                onClick={goTo(`/payment/session/${params.sessionId}`)}
                        >
                            <i className={"icon-pencil"}/>
                        </button>
                    </div>}
                    <div className={"col-md-12 g-mb-20"}>
                        <em>{t('payment.session.End date')} : {moment(session.endDate).format('DD, MMM YYYY')}</em>
                        {/* Price */}
                        <span
                            className="d-block g-color-gray-dark-v2 g-font-weight-600 g-font-size-18">CB : {cardPayments.reduce((cumul, curr) => {
                            return cumul + curr.amount
                        }, 0) / 100.0} €</span>
                        <span
                            className="d-block g-color-gray-dark-v2 g-font-weight-600 g-font-size-18">FCFA : {otherPayments.reduce((cumul, curr) => {
                            return cumul + curr.amount
                        }, 0)} FCFA</span>
                        <span
                            className="d-block g-color-gray-dark-v5 g-font-size-11 text-uppercase">{paymentCount} {t("Payment") + (paymentCount > 1 ? 's' : '')}</span>
                        <span
                            className="d-block g-color-gray-dark-v5 g-font-size-11 text-uppercase">{t('payment.session.My contribution')} : {myCardPayments.reduce(cumulator, 0) / 100.0} €</span>
                    </div>
                    <div className="col-md-12 g-mb-50 g-mb-0--lg">
                        <p dangerouslySetInnerHTML={{__html: session?.description}}/>
                    </div>
                </div>
            </div>
            <div className={"col-lg-5"}>
                <div className="row">
                    <div className="g-mb-50 g-mb-0--lg">
                        {/* General Forms */}
                        <form className="g-brd-around g-brd-gray-light-v4 g-pa-30"
                              onSubmit={onSubmit}
                        >
                            {/* Yellow Alert */}
                            {Boolean(message) &&
                                <div className="alert alert-dismissible fade show g-bg-yellow rounded-0"
                                     role="alert">
                                    <button type="button" className="close u-alert-close--light" data-dismiss="alert"
                                            aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>

                                    <div className="media">
                                <span className="d-flex g-mr-10 g-mt-5">
                                  <i className="icon-info g-font-size-25"/>
                                </span>
                                        <span className="media-body align-self-center">
                                    <strong>Information !</strong> {message}
                                </span>
                                    </div>
                                </div>}
                            {/* End Yellow Alert */}
                            <div className="form-group g-mb-20">
                                <div className="btn-group justified-content">
                                    {services.map((value, index) => serviceRender(value, index))}
                                </div>
                            </div>
                            {/* Text Input */}
                            <div className="form-group g-mb-20">
                                <label className="g-mb-10" htmlFor="fullName">{t('payment.session.Full name')}</label>
                                <div className="input-group g-brd-primary--focus">
                                    <div className="input-group-prepend">
                                    <span className="input-group-text rounded-0 g-bg-white g-color-gray-light-v1"><i
                                        className="icon-user"/></span>
                                    </div>
                                    <input
                                        className="form-control form-control-md border-left-0 border-right-0 rounded-0 px-0"
                                        type="text"
                                        placeholder="Nom complet"
                                        value={name}
                                        readOnly/>
                                    <div className="input-group-append">
                                    <span className="input-group-text rounded-0 g-bg-white g-color-gray-light-v1"><i
                                        className="icon-lock"/></span>
                                    </div>
                                </div>
                            </div>


                            {/* End Text Input */}
                            <div className="form-group g-mb-20">
                                <label className="g-mb-10" htmlFor="amount">{t('payment.session.Amount')}</label>
                                <div className="btn-group justified-content">
                                    {getAmounts().map((value, index) => amountRender(value, index))}
                                </div>
                                {selectedAmount === 'xxx' && <div className="form-group  g-mb-20">
                                    <label className="g-mb-10">{t('payment.session.Custom amount')}</label>
                                    <div className="input-group g-brd-primary--focus">
                                        <input className="form-control form-control-md border-right-0 rounded-0 pr-0"
                                               type="number"
                                               min={10}
                                               step={0.01}
                                               placeholder={service === 'stripe' ? "100" : '25000'}
                                               required
                                               onChange={(e) => setAmount(e.target.value * 1)}/>
                                        <div className="input-group-append">
                                    <span
                                        className="input-group-text rounded-0 g-bg-white g-color-gray-light-v1">{service === 'stripe' ? '€' : 'FCFA'}</span>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <button type="submit"
                                    disabled={loading}
                                    className={`btn btn-${iParticipate ? 'success' : 'primary'} btn-lg btn-block g-mr-10 g-mb-15`}>
                                {loading ? <>
                                    <i className="fa fa-refresh fa-spin"/>
                                    <span className="sr-only">{t('Loading')}...</span>
                                </> : `${t('Pay')} ${amount} ${service === 'stripe' ? '€' : 'FCFA'}`
                                }
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        {/* Demo modal window */}
        <div id="complete-payment" className="text-left g-max-width-700 g-bg-white g-overflow-y-auto g-pa-20"
             style={{display: 'none'}}>
            <h4 className="g-mb-20">{t('payment.session.Complete the payment')}</h4>
            {Boolean(paymentUrl) &&
                <iframe src={`${paymentUrl}&redirect=${window.location.protocol}//${window.location.host}/payment`}
                        width={600} height={650} style={{border: 'none'}} title={session?.title}/>
            }
        </div>
        {/* End Demo modal window */}
    </div>
}