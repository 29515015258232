import React, {useCallback, useEffect, useState} from "react";

import {DDChart, sendErrorAlert} from '@djammadev/react-lib'
import {get} from "../utils";
import t from "../i18n/translator"
import {useHistory} from "react-router-dom";

export default function StatItem(props) {

    const [data, setData] = useState(props.data || [])

    const {path, parser} = props

    const history = useHistory()

    const parse = useCallback((result) => {
        if (typeof parser === 'function') {
            return parser(result)
        }
        if (typeof parser === 'string') {
            return result[parser]
        }
        return result
    }, [parser])

    const handleClick = (event, charts) => {
        if(typeof props.onClick === 'function') {
            props.onClick(event, charts)
        } else if (charts && charts.length > 0) {
            const index = charts[0]._index
            const data = charts[0]._chart?.config?.data
            history.push(`/annuaire?text=${data?.labels[index]}`)
        }
    }

    useEffect(() => {
        if (path) {
            get(path)
                .then(response => {
                    const {data: result} = response
                    setData(parse(result) || [])
                })
                .catch(error => {
                    const response = error.response;
                    console.log('response', response);
                    sendErrorAlert({title: t('Stat error'), message: response?.data?.message})
                })
        }
    }, [path, parse])

    const accumulator = (values) => {
        return values.map((item) => item.value).reduce((cumulus, curr) => {
            return cumulus + curr
        }, 0)
    }

    const defaultOptions = {
        responsive: true,
        scales: {
            x: {
                display: false
            },
            y: {
                display: false
            }
        },
        title: {
            display: true,
            position: props.position || "top",
            fontSize: props.fontSize || 24,
            text: t(props.label)
        }
    }
    const options = Object.assign(defaultOptions, props.options || {})

    return <>
        <DDChart
            onClick={handleClick}
            type={props.type}
            options={options}
            labelKey={props.labelKey || "label"}
            accumulator={props.accumulator || accumulator}
            data={data}
            fill={props.fill}
            height={props.height}
            width={props.width}/>
    </>
}
