import React, {useEffect, useState} from "react";

import LoginForm from "./LoginForm";
import {get, parseQueryString, refreshTokenSetup, storeToken} from "../utils";
import {useHistory} from "react-router-dom";


export default function Login(props) {
    const history = useHistory();
    const [isConnected, setIsConnected] = useState(false);
    const onSuccess = (res) => {
        props.onGoogleSuccess(res);
        console.log("Login Success: currentUser:", res);
        let profile = res.getBasicProfile();
        let token = res.getAuthResponse();
        let profileObj = {
            id: profile.getId(),
            name: profile.getName(),
            imageUrl: profile.getImageUrl(),
            email: profile.getEmail(),
            familyName: profile.getFamilyName(),
            givenName: profile.getGivenName(),
        };
        // /google/signIn
        let data = {profileObj: profileObj, tokenObj: token};
        console.log(data);
        fetch(`/api/auth/google/signIn`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                let headers = res.headers;
                storeToken(res.headers);
                res.json()
                    .then(data => {
                        if (data.code && data.code / 100 !== 2) {
                            console.log('data', data);
                        } else {
                            props.onLoggedIn({data, headers});
                        }
                    });
            })
            .catch((err) => console.error(err));
        refreshTokenSetup(data);
    };

    const onFailure = (res) => {
        console.log("Login failed: res:", res);
        alert(
            "Please give the this message to your administrator : "+ JSON.stringify(res)
        );
    };

    const onLoginSuccess = (response) => {
        const {headers} = response;
        storeToken(headers);
        props.onLoggedIn(response);
    }

    useEffect(() => {
        let params = parseQueryString();
        let code = params.code;
        let scope = params.scope;
        if (code && !isConnected) {
            get(`/api/auth/google/callback?code=${code}&scope=${scope}`)
                .then((response) => {
                    console.log("response", response);
                    setIsConnected(true);
                    storeToken(response.headers);
                    onLoginSuccess(response);
                    history.push('/');
                })
                .catch((err) => console.error(err));
        } else {
            get('/api/auth')
                .then(response => {
                    console.log('response', response);
                })
                .catch(err => {
                    console.error('err', err);
                });
        }
    });

    document.title = 'Connexion CPD'

    return (
        <>
            <LoginForm
                clientId={props.clientId}
                scope={props.scope}
                onSuccess={onSuccess}
                onFailure={onFailure}
                onLoginSuccess={onLoginSuccess}
                gapi={props.gapi}
                paymentOnly={props.paymentOnly}
            />
        </>
    );
}
