import React from "react";

export default function UnifyFooter(props) {
  return (
    <>
      {/* Footer */}
      <div
        id="contacts-section"
        className="g-bg-black-opacity-0_9 g-color-white-opacity-0_8 g-py-60"
      >
        <div className="container">
          <div className="row">
            {/* Footer Content */}
            <div className="col-lg-6 col-md-6 g-mb-40 g-mb-0--lg">
              <div className="u-heading-v2-3--bottom g-brd-white-opacity-0_8 g-mb-20">
                <h2 className="u-heading-v2__title h6 text-uppercase mb-0">
                  A propos de nous
                </h2>
              </div>

              <p>
                La CPD est une association a but non lucratif.
                Son seul objectif est de contribuer autant que se peut au developpement du Mali.
              </p>
            </div>
            {/* End Footer Content */}

            {/* Footer Content */}
            <div className="col-lg-6 col-md-6">
              <div className="u-heading-v2-3--bottom g-brd-white-opacity-0_8 g-mb-20">
                <h2 className="u-heading-v2__title h6 text-uppercase mb-0">
                  Notre Contact
                </h2>
              </div>

              <address className="g-bg-no-repeat g-font-size-12 mb-0">
                {/* Location */}
                <div className="d-flex g-mb-20">
                  <div className="g-mr-10">
                    <span className="u-icon-v3 u-icon-size--xs g-bg-white-opacity-0_1 g-color-white-opacity-0_6">
                      <i className="fa fa-map-marker"/>
                    </span>
                  </div>
                  <p className="mb-0">
                    Valence 26000
                  </p>
                </div>
                {/* End Location */}

                {/* Phone */}
                {/*<div className="d-flex g-mb-20">
                  <div className="g-mr-10">
                    <span className="u-icon-v3 u-icon-size--xs g-bg-white-opacity-0_1 g-color-white-opacity-0_6">
                      <i className="fa fa-phone"/>
                    </span>
                  </div>
                  <p className="mb-0">

                  </p>
                </div>*/}
                {/* End Phone */}

                {/* Email and Website */}
                <div className="d-flex g-mb-20">
                  <div className="g-mr-10">
                    <span className="u-icon-v3 u-icon-size--xs g-bg-white-opacity-0_1 g-color-white-opacity-0_6">
                      <i className="fa fa-globe"/>
                    </span>
                  </div>
                  <p className="mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="mailto:info@htmlstream.com"
                    >
                      cpd@cpd-mali.com
                    </a>
                    <br />
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="/#"
                    >
                      www.cpd-mali.com
                    </a>
                  </p>
                </div>
                {/* End Email and Website */}
              </address>
            </div>
            {/* End Footer Content */}
          </div>
        </div>
      </div>
      {/* End Footer */}
    </>
  );
}
