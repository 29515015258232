import React, {useEffect, useState} from "react";
import {get} from "../utils";

import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import t from "../i18n/translator";

const filter = createFilterOptions();

export default function FieldSelect(props) {
    const [options, setOptions] = useState(props.options || []);
    const [loading, setLoading] = useState(false);
    const value = props.value || '';
    const {path, field, labelField, select, name, fieldId, onChange, queryFilter} = props;

    useEffect(() => {
        if(path) {
            //overlay($, true);
            setLoading(true);
            let query = `/api/${path}/list?page=-1&select=${select}&orderBy=${labelField || field}&${field}=!NULL`;
            if(queryFilter) {
                query=`${query}&${queryFilter}`
            }
            get(query)
                .then(({data}) => {
                    //overlay($, false);
                    setLoading(false);
                    let objOptions = data.filter(opt => opt[field] !== null && `${opt[field]}`.trim().length > 0)
                        .reduce((cumul, curr) => {
                        return {...cumul, [curr[field].toLowerCase()]: curr}
                    }, {});
                    let dataOptions = [];
                    for (let key in objOptions) {
                        if (objOptions.hasOwnProperty(key)) {
                            dataOptions.push(objOptions[key]);
                        }
                    }
                    setOptions(dataOptions);
                })
                .catch(error => {
                    setLoading(false);
                    //overlay($, false);
                    console.log(error.response);
                });
        }
    }, [path, field, labelField, select, queryFilter]);

    const handleChange = (e) => {
        if(typeof onChange === 'function') {
            onChange(e);
        }
    }
    return <>
        <Autocomplete
            className={`g-brd-gray-light-v2 g-color-gray-dark-v2 w-100`}
            value={value}
            loading={loading}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    handleChange({
                        target: {
                            name,
                            value: newValue
                        }
                    });
                    //setText(newValue);
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    handleChange({
                        target: {
                            name,
                            value: newValue.inputValue
                        }
                    });
                } else {
                    handleChange({
                        target: {
                            name,
                            value: newValue ? newValue[field] : ''
                        }
                    });
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                    if (props.freeSolo !== false) {
                        filtered.push({
                            inputValue: params.inputValue,
                            [field]: `${props.addButton} "${params.inputValue}"`,
                        });
                    } else {
                        filtered.push({
                            inputValue: 'Other',
                            [field]: t('Other'),
                        });
                    }
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id={fieldId}
            options={options}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return t({key: option[labelField] || option[field]});
            }}
            renderOption={(option) => t({key: option[field]})}
            freeSolo={props.freeSolo !== false}
            renderInput={(params) => (
                <TextField {...params} label={props.placeholder} variant="outlined" />
            )}
        />
    </>
}