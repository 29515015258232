import React, {useEffect, useState} from "react";

import * as moment from 'moment';
import truncate from "truncate-html";
import {useHistory} from "react-router-dom";
import {cumulator, get, overlay} from "../utils";
import t from '../i18n/translator'

const {$, ddPaymentWindow} = window;

export default function PaymentSession(props) {
    const history = useHistory();

    const [sessions, setSessions] = useState([]);

    const goTo = (path) => {
        return (e) => {
            e.preventDefault();
            history.push(path);
        };
    }

    const loadSessions = async () => {
        overlay($, true);
        try {
            let response = await get('/api/session/list?perPage=-1&page=-1&orderBy=endDate desc');
            if (response.data.length > 0)
                setSessions(response.data);
        } catch (e) {
            console.log('error', e);
        } finally {
            overlay($, false);
        }

    }

    useEffect(() => {
        if (sessions.length === 0) {
            loadSessions();
        }
    }, [sessions]);

    useEffect(() => {
        if (ddPaymentWindow && typeof ddPaymentWindow.close === 'function') {
            ddPaymentWindow.close();
        }
    });

    const getSuccessCardPayments = (session) => {
        return (session.payments || []).filter(pay => pay.success);
    }

    const getMyPayments = (session) => {
        return (session.payments || []).filter(pay => pay.ownerId === props.userId && pay.success);
    }

    document.title = 'CPD : Session de paiements';

    return <div className={"row"}>
        {sessions.length > 0 && <div className={"col-md-12"}>
            <button className={"btn btn-primary"}
                    onClick={goTo(`/payment/session/new`)}
            >
                <i className={"icon-plus"}/>
            </button>
        </div>}
        {sessions.length === 0 && <div className="align-middle text-center">
            <h2>{t('No payment session created')}</h2>
            {props.canEdit && <div className={"col-lg-12"}>
                <button className={"btn btn-primary"}
                        onClick={goTo(`/payment/session/new`)}><i className={"icon-plus"}/> {t('Add new session')}
                </button>
            </div>}
        </div>}
        {sessions.map(session => {
            let payments = getSuccessCardPayments(session);
            const myPayments = getMyPayments(session);
            const iParticipate = (myPayments || []).length > 0;
            return (
                <article key={session.id} className="d-md-table w-100 g-bg-white g-mb-1"
                         style={{borderBottom: '1px dashed'}}>
                    {/* Date */}
                    <div
                        className="d-md-table-cell align-middle g-width-125--md text-center g-color-gray-dark-v5 g-py-10 g-px-20">
                        <time dateTime={moment(session.startDate).format('YYYY-MM-DD')}>
                            <span
                                className="d-block g-color-black g-font-weight-700 g-font-size-40 g-line-height-1">{moment(session.startDate).format('DD')}</span>
                            {moment(session.startDate).format('MMM, YYYY')}
                        </time>
                    </div>
                    {/* End Date */}

                    {/* Article Image */}
                    <a className="d-md-table-cell align-middle g-width-130" href={`/payment/${session.id}`}
                       onClick={goTo(`/payment/${session.id}`)}>
                        <img className="d-block info-v5-2__image g-ml-minus-1"
                             src="https://via.placeholder.com/150.jpg"
                             alt="Session Preview"/>
                    </a>
                    {/* End Article Image */}

                    {/* Article Content */}
                    <div className="d-md-table-cell align-middle g-py-15 g-px-20 g-min-width-200">
                        <h3 className="h3 g-font-weight-700 text-uppercase">
                            <a className="g-color-gray-dark-v2" href={`/payment/${session.id}`}
                               onClick={goTo(`/payment/${session.id}`)}>{session.title}</a>
                        </h3>
                        <em className="g-color-gray-dark-v5 g-font-style-normal"
                            dangerouslySetInnerHTML={{__html: truncate(session.description, 50, {byWords: true})}}/>
                        <br/>
                        <em>{t('payment.session.End date')} : {moment(session.endDate).format('DD, MMM YYYY')}</em>
                        {/* Price */}
                        <span
                            className="d-block g-color-gray-dark-v2 g-font-weight-700 g-font-size-24">{Math.floor(payments.reduce(cumulator, 0)) / 100.0} €</span>
                        <span
                            className="d-block g-color-gray-dark-v5 g-font-size-11 text-uppercase">{payments.length} {t("Payment") + (payments.length > 1 ? 's' : '')}</span>
                        <span
                            className="d-block g-color-gray-dark-v5 g-font-size-11 text-uppercase">{t('payment.session.My contribution')} : {Math.floor(myPayments.reduce(cumulator, 0)) / 100.0} €</span>

                        {/* End Price */}
                    </div>
                    {/* End Article Content */}

                    {/* Actions */}
                    <div className="d-md-table-cell align-middle text-md-right g-pa-20 g-min-width-200">
                        <div className="g-mt-minus-10 g-mx-minus-5">
                            <a className={`btn btn-lg btn-${iParticipate ? 'success' : 'primary'} g-font-weight-600 g-font-size-12 text-uppercase g-mx-5 g-mt-10`}
                               onClick={goTo(`/payment/${session.id}${session.amount ? "?amount=" + (session.amount / 100) : ""}`)}
                               href="/#">{t('Contribute')}</a>
                        </div>
                    </div>
                    {/* End Actions */}
                </article>
            )
        })}
    </div>
}