import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import t from "../i18n/translator";

const {$} = window;

export const DATE_FORMAT = 'dd/mm/yy';
export const DATE_FORMAT_MOMENT = 'DD/MM/YYYY';
export const YYYY_MM_DD = 'YYYY-MM-DDTHH:mm:ssZ';
export default function DateRange(props) {
    let dateFormat = t({key: 'dateFormat', defaultValue: DATE_FORMAT_MOMENT});
    const [startDate, setStartDate] = useState(Boolean(props.startDate) ? moment(props.startDate, YYYY_MM_DD).format(dateFormat) : '');
    const [endDate, setEndDate] = useState(Boolean(props.endDate) ? moment(props.endDate, YYYY_MM_DD).format(dateFormat) : '');

    const {onChange, disabledDates} = props;

    const showDay = useCallback((date) => {
        const initial = moment();
        const inp = moment(date);
        return (disabledDates || []).reduce((prev, current) => {
            const from = moment(moment(current.start).format(YYYY_MM_DD));
            const to = moment(moment(current.end).format(YYYY_MM_DD));
            const currentDate = moment(date);
            const between = currentDate.isSameOrAfter(from) && currentDate.isSameOrBefore(to);
            return prev && !between
        }, true) && initial.isSameOrAfter(inp);
    }, [disabledDates]);

    const beforeShowDayTo = useCallback((date) =>  {
        const currentDate = moment(date);
        const sDate = moment(startDate, dateFormat);
        const dDates = (disabledDates || []).map(item => {
            return {
                start: moment(moment(item.start).format(YYYY_MM_DD)),
                end: moment(moment(item.end).format(YYYY_MM_DD))
            }
        }).filter(item => {
            return sDate.isValid() && (item.start.isAfter(sDate) || item.end.isAfter(sDate));
        }).sort((a, b) => {
            return a.start.isAfter(b.start) ? 1 : (a.start.isBefore(b.start) ? -1 : 0)
        });
        const firstBook = dDates.length > 0 ? dDates[0] : null;
        return [(!firstBook || currentDate.isBefore(firstBook.start)) && showDay(date)];
    }, [startDate, disabledDates, showDay, dateFormat]);

    const beforeShowDayFrom = useCallback((date) => {
        return [showDay(date)];
    }, [showDay]);

    const {fromId, toId, readOnly} = props;

    const handleChange = useCallback((e) => {
        let date = moment(e.target.value, dateFormat);
        let value = e.target.value;
        if(date.isValid()) {
            if (typeof onChange === 'function') {
                onChange(e);
            }
        }
        if (e.target.name === 'startDate') {
            setStartDate(value);
        } else if (e.target.name === 'endDate') {
            setEndDate(value);
        }
    }, [onChange, dateFormat]);

    useEffect(() => {
        let df = t({key: 'datepickerFormat', defaultValue: DATE_FORMAT});
        let config = {
            dateFormat: df,
            onChange: handleChange,
            beforeShowDayFrom: beforeShowDayFrom,
            beforeShowDayTo: beforeShowDayTo
        }

        if(readOnly !== true && Boolean(fromId) && Boolean(toId)) {
            $.HSCore.components.HSDatepicker.init(`#${fromId}` || '#datepickerFrom', config);
            if ((disabledDates || []).length > 0) {
                $(`#${fromId}` || '#datepickerFrom').datepicker('destroy');
                $(`#${toId}` || '#datepickerTo').datepicker('destroy');
                $.HSCore.components.HSDatepicker.init(`#${fromId}` || '#datepickerFrom', config);
            }
        }
    }, [readOnly, fromId, toId, beforeShowDayFrom, beforeShowDayTo, disabledDates, handleChange]);

    let myStartDate = startDate || Boolean(props.startDate) ? moment(props.startDate, YYYY_MM_DD).format(dateFormat) : '';
    let myEndDate = endDate || Boolean(props.endDate) ? moment(props.endDate, YYYY_MM_DD).format(dateFormat) : '';
    return <>
        {/* Select Date Range */}
        <div className="row">
            <div className={`${props.range ? 'col-xl-6' : 'col-xl-12'} g-mb-40 g-mb-0--xl`}>
                {/* Datepicker */}
                <div className="input-group g-brd-primary--focus">
                    <input id={fromId}
                           className="form-control form-control-md u-datepicker-v1 g-brd-right-none rounded-0"
                           type="text"
                           name={"startDate"}
                           value={myStartDate}
                           onChange={handleChange}
                           readOnly={props.readOnly}
                           placeholder={dateFormat}
                           data-range={props.range}
                           data-to={toId}/>
                    <div className="input-group-append">
                            <span className="input-group-text rounded-0 g-color-gray-dark-v5"><i
                                className="icon-calendar"/></span>
                    </div>
                </div>
                {/* End Datepicker */}
            </div>

            {props.range && <div className="col-xl-6">
                {/* Datepicker */}
                <div className="input-group g-brd-primary--focus">
                    <input id={toId}
                           className="form-control form-control-md u-datepicker-v1 g-brd-right-none rounded-0"
                           type="text"
                           name={"endDate"}
                           value={myEndDate}
                           onChange={handleChange}
                           readOnly={props.readOnly}
                           placeholder={dateFormat}/>
                    <div className="input-group-append">
                            <span className="input-group-text rounded-0 g-color-gray-dark-v5"><i
                                className="icon-calendar"/></span>
                    </div>
                </div>
                {/* End Datepicker */}
            </div>}
        </div>
        {/* End Select Date Range */}
    </>
}