import React, {useCallback, useEffect, useState} from "react";
import {convertSkills, UserBlock} from "./ProfileView";
import {get, goTo, goToTop, initCharts, overlay, PROFILE_LIST_FIELDS, queryParams} from "../utils";
import {useHistory, useLocation} from "react-router-dom";
import Translator from "../i18n/translator";

const {$, parseQueryString} = window;

export function Pagination(props) {

    const {page, pageCount} = props

    return <>
        {/* Pagination */}
        <nav className="text-center" aria-label="Page Navigation">
            <ul className="list-inline">
                <li className="list-inline-item float-sm-left">
                    <button className="u-pagination-v1__item u-pagination-v1-4 g-rounded-50 g-pa-7-16"
                            disabled={page === 1}
                            aria-label={Translator({key: 'userList.previous'})} onClick={props.onPrevious}>
                        <span aria-hidden="true">
                          <i className="fa fa-angle-left g-mr-5"/> {Translator({key: 'userList.previous'})}
                        </span>
                        <span className="sr-only">{Translator({key: 'userList.previous'})}</span>
                    </button>
                </li>
                <li className="list-inline-item g-hidden-sm-down">
                    <a className={`u-pagination-v1__item u-pagination-v1-4 ${page === 1 ? 'u-pagination-v1-4--active' : ''} g-rounded-50 g-pa-7-14`}
                       onClick={(e) => props.goOn(e, 1)}
                       href="/#">1</a>
                </li>
                {pageCount > 1 && page > 1 && page < 5 && <li className="list-inline-item g-hidden-sm-down">
                    <a className={`u-pagination-v1__item u-pagination-v1-4 ${page === 2 ? 'u-pagination-v1-4--active' : ''} g-rounded-50 g-pa-7-14`}
                       onClick={(e) => props.goOn(e, 2)}
                       href="/#">2</a>
                </li>}
                {pageCount > 1 && page === 4 && <li className="list-inline-item g-hidden-sm-down">
                    <a className={`u-pagination-v1__item u-pagination-v1-4 ${page === 3 ? 'u-pagination-v1-4--active' : ''} g-rounded-50 g-pa-7-14`}
                       onClick={(e) => props.goOn(e, 3)}
                       href="/#">3</a>
                </li>}
                {page > 4 && <li className="list-inline-item g-hidden-sm-down">
                    <a className="g-pa-7-14" href={"/#"} onClick={(e) => e.preventDefault()}>...</a>
                </li>}
                {page > 4 && [page - 2, page - 1].map(p => {
                    return <li key={p} className="list-inline-item g-hidden-sm-down">
                        <a className={`u-pagination-v1__item u-pagination-v1-4 ${page === p ? 'u-pagination-v1-4--active' : ''} g-rounded-50 g-pa-7-14`}
                           onClick={(e) => props.goOn(e, p)}
                           href="/#">{p}</a>
                    </li>
                })}
                {page > 2 && <li className="list-inline-item g-hidden-sm-down">
                    <a className={`u-pagination-v1__item u-pagination-v1-4 u-pagination-v1-4--active g-rounded-50 g-pa-7-14`}
                       onClick={(e) => props.goOn(e, page)}
                       href="/#">{page}</a>
                </li>}
                {pageCount - page >= 3 && [page + 1, page + 2].map(p => {
                    return <li key={p} className="list-inline-item g-hidden-sm-down">
                        <a className={`u-pagination-v1__item u-pagination-v1-4 ${page === p ? 'u-pagination-v1-4--active' : ''} g-rounded-50 g-pa-7-14`}
                           onClick={(e) => props.goOn(e, p)}
                           href="/#">{p}</a>
                    </li>
                })}
                {page + 3 < pageCount && <li className="list-inline-item g-hidden-sm-down">
                    <a className="g-pa-7-14" href={"/#"} onClick={(e) => e.preventDefault()}>...</a>
                </li>}
                {pageCount - page === 2 && <li className="list-inline-item g-hidden-sm-down">
                    <a className={`u-pagination-v1__item u-pagination-v1-4 ${page === pageCount - 1 ? 'u-pagination-v1-4--active' : ''} g-rounded-50 g-pa-7-14`}
                       onClick={(e) => props.goOn(e, pageCount - 1)}
                       href="/#">{pageCount - 1}</a>
                </li>}
                {page !== pageCount && pageCount > 0 && <li className="list-inline-item g-hidden-sm-down">
                    <a className={`u-pagination-v1__item u-pagination-v1-4 ${page === pageCount ? 'u-pagination-v1-4--active' : ''} g-rounded-50 g-pa-7-14`}
                       onClick={(e) => props.goOn(e, pageCount)}
                       href="/#">{pageCount}</a>
                </li>}
                <li className="list-inline-item float-sm-right">
                    <button className="u-pagination-v1__item u-pagination-v1-4 g-rounded-50 g-pa-7-16"
                            disabled={page === pageCount}
                            onClick={props.onNext}
                            aria-label={Translator({key: 'userList.next'})}>
                        <span aria-hidden="true">
                          {Translator({key: 'userList.next'})} <i className="fa fa-angle-right g-ml-5"/>
                        </span>
                        <span className="sr-only">{Translator({key: 'userList.next'})}</span>
                    </button>
                </li>
            </ul>
        </nav>
        {/* End Pagination */}
    </>
}

function OrderByItem(props) {
    const getDirection = (option) => {
        if (option.value === '') {
            return '';
        }
        if (option.value === true) {
            return props.on === 'asc' ? 'icon-arrow-up' : 'icon-arrow-down';
        }
        if (option.value === false) {
            return props.off === 'asc' ? 'icon-arrow-up' : 'icon-arrow-down';
        }
    }
    const icon = getDirection(props);
    return <>
        <label className="u-check g-pl-0">
            <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                   type="checkbox"
                   onChange={props.onChange}
                   name={props.name}
                   checked={props.value}/>
            <span
                className="btn btn-md btn-block u-btn-outline-lightgray g-color-white--checked g-bg-primary--checked rounded-0"><i
                className={icon}/> {props.label}</span>
        </label>
    </>
}

export function OrderBy(props) {
    const options = props.options || [];

    const handleItem = (e) => {
        let name = e.target.name;
        let newOptions = options.map(option => {
            let opt = Object.assign({}, option);
            if (name === 'reset') {
                opt.value = '';
            } else if (opt.name === name) {
                if (option.value === '') {
                    opt.value = true
                } else if (option.value === false) {
                    opt.value = ''
                } else {
                    opt.value = false;
                }
            }
            return opt;
        });
        if (typeof props.onChange === 'function') {
            props.onChange(newOptions);
        }
    }

    return <>
        <div className="btn-group justified-content">
            {options.map(option => <OrderByItem key={option.name} {...option} onChange={handleItem}/>)}
        </div>
    </>
}

export default function UserList(props) {
    let location = useLocation();
    const history = useHistory();
    const search = location.search;
    const queries = parseQueryString(search);
    let queryPage = queries.page * 1
    const [page, setPage] = useState(queryPage ? queryPage : 1);
    const [perPage, setPerPage] = useState(10);
    const [profiles, setProfiles] = useState([]);
    const [entityCount, setEntityCount] = useState(0);
    const [pageCount, setPageCount] = useState(10);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('');

    const [options, setOptions] = useState([
        {name: 'score', label: Translator({key: 'profile.score'}), value: true, on: 'desc', off: 'asc'},
        {name: 'lastName', label: Translator({key: 'profile.firstName'}), value: '', on: 'asc', off: 'desc'},
        {name: 'firstName', label: Translator({key: 'profile.lastName'}), value: '', on: 'asc', off: 'desc'},
        {name: 'reset', label: Translator({key: 'userList.reset'}), value: ''}]);

    const onNext = (e) => {
        e.preventDefault();
        if (page < pageCount) {
            setPage(page + 1);
        }
    };

    const onPrevious = (e) => {
        e.preventDefault();
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const goOn = (e, targetPage) => {
        console.log(targetPage, e);
        e.preventDefault();
        if (targetPage >= 1 && targetPage <= pageCount) {
            setPage(targetPage);
        }
    };

    const changePerPage = (perPage) => {
        setPerPage(perPage);
    };

    const queryText = queries.text;

    useEffect(() => {
        setPage(1);
    }, [search]);

    useEffect(() => {
        if (queryText) {
            setText(queryText);
        } else {
            setText('');
        }
    }, [queryText]);

    let orderBy = options.filter(opt => opt.value !== '' && opt.name !== 'reset').map(option => {
        return `${option.name} ${option.value === true ? option.on : option.off}`
    }).join(',');

    if (orderBy.length === 0) {
        orderBy = 'modifyDate desc';
    } else {
        orderBy = `${orderBy},modifyDate desc`;
    }

    const loadProfiles = useCallback(async () => {
        goToTop($);
        setLoading(true);
        let queries = parseQueryString(search);
        queries['page'] = page;
        queries['perPage'] = perPage;
        if (orderBy.length !== '') {
            queries['orderBy'] = orderBy;
        }
        delete queries.text;
        delete queries.select;
        let actualText = queryText;
        if (actualText) {
            actualText = `${actualText}`.trim()
        }
        let path = `/api/profile?${queryParams(queries)}`
        if (actualText && actualText.length > 0) {
            let or = "firstName_IN_;lastName_IN_;jobTitle_IN_;company_IN_;experiences.company_IN_;experiences.sector_IN_;formations.institution_IN_;skills.title_IN_;languages.title_IN_;languages.level_IN_;user.promotion;CONCAT(firstName,' ',lastName)_IN_;CONCAT(lastName,' ',firstName)_IN_;experiences.address_IN_;formations.address_IN_;address.country_IN_;address.city_IN_";
            path = `/api/profile/search?${queryParams(queries)}&firstName_IN_=${actualText}&lastName_IN_=${actualText}&jobTitle_IN_=${actualText}&company_IN_=${actualText}&experiences.company_IN_=${actualText}&formations.institution_IN_=${actualText}&experiences.sector_IN_=${actualText}&skills.title_IN_=${actualText}&languages.title_IN_=${actualText}&languages.level_IN_=${actualText}&experiences.address_IN_=${actualText}&formations.address_IN_=${actualText}&address.country_IN_=${actualText}&address.city_IN_=${actualText}&user.promotion=${actualText}&CONCAT(firstName,' ',lastName)_IN_=${actualText}&CONCAT(lastName,' ',firstName)_IN_=${actualText}&or=${or}`;
            console.log('or', or);
        }
        if (queries.mali) {
            path = `${path}&address.country=Mali`
        }
        path = `${path}&user.anonymous=false&select=${PROFILE_LIST_FIELDS}`
        console.log('path', path);
        try {
            let {
                data: responseData
            } = await get(path);
            console.log('responseData', responseData);
            const {
                totalEntityCount: entityCount,
                entities: profiles,
                pageCount
            } = responseData;
            setEntityCount(entityCount);
            setProfiles(profiles);
            setPageCount(pageCount);
        } catch (e) {
            console.log('error', e);
        }
        setLoading(false);
    }, [page, perPage, search, orderBy, queryText])

    const handleSubmit = (e) => {
        e.preventDefault();
        loadProfiles();
    }

    const handleChange = (e) => {
        const value = e.target.value;
        const newQuery = Object.assign({}, queries);
        newQuery.text = value
        history.push(`/annuaire?${queryParams(newQuery)}`)
        //setText(value);
        // setPage(1);
    }

    useEffect(() => {
        loadProfiles();
    }, [loadProfiles, queryText]);

    useEffect(() => {
        initCharts($);
    });

    useEffect(() => {
        overlay($, loading);
    }, [loading]);

    const getGoTo = (path) => {
        return goTo(history, path)
    }

    document.title = 'CPD : Annuaire';

    return <>
        {/*<Loader loading={loading}/>*/}
        <section className="overlay g-mb-100">

            <div className="container">
                <div className="row">
                    <div className={"col-lg-1"}>
                    </div>
                    <div className="col-lg-10">
                        <div className="mx-auto g-mb-20">
                            <div className="input-group">
                                <input type="text" className="form-control g-font-size-16"
                                       placeholder={Translator({key: 'userList.search'}) + "..."}
                                       aria-label={Translator({key: 'userList.search'}) + "..."}
                                       value={text}
                                       name={"text"}
                                       onChange={handleChange}
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-primary g-font-size-18 g-py-12 g-px-25"
                                            onClick={handleSubmit}
                                            type="submit">
                                        <i className="fa fa-search"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={"g-mb-15"}>
                            <OrderBy options={options} onChange={opts => setOptions(opts)}/>
                        </div>
                        <h4>{entityCount} {Translator({key: 'userList.result'})}{entityCount > 1 ? "s" : ""}</h4>
                        {entityCount > 0 && <Pagination page={page}
                                                        pageCount={pageCount}
                                                        onNext={onNext}
                                                        onPrevious={onPrevious}
                                                        goOn={goOn}
                                                        changePerPage={changePerPage}/>}
                        {(profiles || []).map(profile => {
                            return <UserBlock key={profile.id}
                                              {...profile}
                                              goTo={getGoTo}
                                              skills={convertSkills(profile)}
                                              list/>
                        })}
                        {entityCount > 0 && <Pagination page={page}
                                                        pageCount={pageCount}
                                                        onNext={onNext}
                                                        onPrevious={onPrevious}
                                                        goOn={goOn}
                                                        changePerPage={changePerPage}/>}
                    </div>
                </div>
            </div>
        </section>
    </>
}