import FR from './fr.json';
import EN from './en.json';

const LANGS = {
    fr: FR,
    en: EN
};

const {language} = navigator;

export default function Translator(props) {
    let textKey;
    if (typeof props === 'string') {
        textKey = props;
    } else {
        textKey = props.key;
    }
    let parts = textKey.split('.');
    let lang = language.split('-')[0];
    let root = LANGS[lang] || FR;
    if (!root.hasOwnProperty(parts[0])) {
        root = textKey;
    }
    for (let key of parts) {
        if(root && root.hasOwnProperty(key)) {
            root = root[key]
        } else {
            root = `${key.charAt(0).toUpperCase()}${key.substr(1)}`
        }
    }
    if (!root) {
        root = props.defaultValue;
    }
    return root ? `${root}` : '';
}