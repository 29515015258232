import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {goTo, initAnimation, queryParams} from "../utils";
import t from "../i18n/translator";
import StatItem from "./Stats";
import UnifyMain from "./UnifyMain";

import moment from "moment"
import {YYYY_MM_DD} from "./DateRange";

import 'chartjs-plugin-labels'

const {$} = window;

/*
function Banner(props) {
    return <>
        <section className="g-flex-centered g-bg-pos-center g-bg-img-hero g-py-150"
                 style={{backgroundImage: `url(/assets/img/home-2.jpg)`}}
                 data-calc-target="#js-header">
            <div className="container text-center g-z-index-1">
                <div className="g-mb-50"
                     data-animation="fadeInUp"
                     data-animation-delay="200"
                     data-animation-duration="1000">
                    <a className="js-fancybox-media rounded-circle u-icon-v2 g-font-size-20 g-color-white g-bg-black g-brd-black g-bg-primary--hover g-brd-primary--hover g-text-underline--none--hover"
                       href="/#"
                       onClick={e => e.preventDefault()}
                       data-src="//www.youtube.com/watch?v=E7Sf7kmFuOA"
                       data-speed="1000"
                       data-animate-in="fadeIn"
                       data-animate-out="fadeOut">
                        <i className="g-pos-rel g-left-2 hs-icon hs-icon-play"/>
                    </a>
                </div>
                <div className="g-mb-25" data-animation="fadeInUp"
                     data-animation-delay="500"
                     data-animation-duration="1000">
                    <h2 className="h4 d-inline-block g-brd-bottom g-brd-2 g-brd-black g-color-white g-font-weight-600 text-uppercase g-pb-5">Cellule
                        Pour le Développement (CPD)</h2>
                </div>
                <h3 className="g-color-white g-font-weight-700 g-font-size-40 g-font-size-55--md text-uppercase g-mb-40"
                    data-animation="fadeInUp"
                    data-animation-delay="800"
                    data-animation-duration="1000">
                    Excellence au service du Mali
                </h3>

                {!props.paymentOnly && <div data-animation="fadeInUp"
                                            data-animation-delay="1100"
                                            data-animation-duration="1000">
                    <SearchForm {...props}/>
                </div>}

                <div data-animation="fadeInUp"
                     data-animation-delay="1100"
                     data-animation-duration="1000">
                    {!props.paymentOnly &&
                    <a className="btn btn-lg u-btn-outline-white g-font-weight-600 g-font-size-13 text-uppercase g-rounded-50 mx-2 g-px-25 g-py-15 g-mb-20"
                       href="/annuaire"
                       onClick={props.goTo('/annuaire')}
                    >Annuaire</a>}
                    <a className="btn btn-lg u-btn-primary g-font-weight-600 g-font-size-13 text-uppercase g-rounded-50 mx-2 g-px-25 g-py-15 g-mb-20"
                       href="/payment"
                       onClick={props.goTo('/payment')}
                    >Contribuer</a>
                </div>
            </div>
        </section>
    </>
}
*/
function MainBanner(props) {
    const [formChanged, setFormChanged] = useState(false);
    return <>
        <section id="home" className="js-carousel g-overflow-hidden g-max-height-100vh"
                 data-autoplay="true"
                 data-infinite="true"
                 data-speed="15000"
                 data-pagi-classes="u-carousel-indicators-v29 container text-center text-uppercase g-absolute-centered--x g-bottom-50 g-line-height-1_2 g-font-size-12 g-color-white g-brd-white-opacity-0_2">
            <div className="js-slide"
                 data-title={t('home.search')}>
                {/* Promo Block */}
                <div
                    className="g-flex-centered g-height-100vh g-min-height-500--md g-bg-cover g-bg-pos-center g-bg-img-hero g-bg-black-opacity-0_5--after"
                    style={{backgroundImage: 'url(/assets/img/home-2.jpg)'}}>
                    <div className="container text-center g-z-index-1">
                        <div className="g-mb-50"
                             data-animation="fadeInUp"
                             data-animation-delay="200"
                             data-animation-duration="1000">
                            <a className="js-fancybox-media rounded-circle u-icon-v2 g-font-size-20 g-color-white g-bg-black g-brd-black g-bg-primary--hover g-brd-primary--hover g-text-underline--none--hover"
                               href="/#"
                               onClick={e => e.preventDefault()}
                               data-src="//www.youtube.com/watch?v=E7Sf7kmFuOA"
                               data-speed="1000"
                               data-animate-in="fadeIn"
                               data-animate-out="fadeOut">
                                <i className="g-pos-rel g-left-2 hs-icon hs-icon-play"/>
                            </a>
                        </div>
                        <div className="g-mb-25" data-animation="fadeInUp"
                             data-animation-delay="500"
                             data-animation-duration="1000">
                            <h2 className="h4 d-inline-block g-brd-bottom g-brd-2 g-brd-black g-color-white g-font-weight-600 text-uppercase g-pb-5">Cellule
                                Pour le Développement (CPD)</h2>
                        </div>
                        <h3 className="g-color-white g-font-weight-700 g-font-size-40 g-font-size-55--md text-uppercase g-mb-40"
                            data-animation="fadeInUp"
                            data-animation-delay="800"
                            data-animation-duration="1000">
                            Excellence au service du Mali
                        </h3>

                        {!props.paymentOnly && <div data-animation="fadeInUp"
                                                    data-animation-delay="1100"
                                                    data-animation-duration="1000">
                            <SearchForm {...props} formChanged={setFormChanged}/>
                        </div>}

                        {formChanged !== true && <div data-animation="fadeInUp"
                                                      data-animation-delay="1100"
                                                      data-animation-duration="1000">
                            {!props.paymentOnly &&
                            <a className="btn btn-lg u-btn-outline-white g-font-weight-600 g-font-size-13 text-uppercase g-rounded-50 mx-2 g-px-25 g-py-15 g-mb-20"
                               href="/annuaire"
                               onClick={props.goTo('/annuaire')}
                            >{t('home.directory')}</a>}
                            <a className="btn btn-lg u-btn-primary g-font-weight-600 g-font-size-13 text-uppercase g-rounded-50 mx-2 g-px-25 g-py-15 g-mb-20"
                               href="/payment"
                               onClick={props.goTo('/payment')}
                            >{t('home.contribute')}</a>
                        </div>}
                    </div>
                </div>
                {/* End Promo Block */}
            </div>

            <div className="js-slide"
                 data-title={t('home.about us')}>
                {/* Promo Block */}
                <div
                    className="g-flex-centered g-height-100vh g-min-height-500--md g-bg-cover g-bg-pos-center g-bg-img-hero g-bg-black-opacity-0_5--after"
                    style={{backgroundImage: 'url(/assets/img/home-1.jpg)'}}>
                    <div className="container text-center g-z-index-1">
                        <h2 className="text-uppercase g-font-weight-700 g-font-size-22 g-font-size-36--md g-color-white g-mb-20">Cellule
                            Pour le Développement du
                            <span className="g-color-primary"> Mali</span> <br/>
                            <span className="g-color-primary"> CPD</span>
                        </h2>
                        <p className="g-hidden-xs-down g-max-width-645 g-color-white-opacity-0_9 mx-auto g-mb-35">{t('home.about')}</p>
                        <div>
                            {!props.paymentOnly &&
                            <a className="btn btn-lg u-btn-outline-white g-font-weight-600 g-font-size-13 text-uppercase g-rounded-50 mx-2 g-px-40 g-py-15 g-mb-20"
                               href="/annuaire"
                               onClick={props.goTo('/annuaire')}
                            >{t('home.directory')}</a>}
                            <a className="btn btn-lg u-btn-primary g-font-weight-700 g-font-size-12 text-uppercase g-rounded-50 mx-2 g-px-40 g-py-15 g-mb-20"
                               target={"_blank"}
                               rel="noreferrer"
                               href="https://www.cpd-mali.com/cellule">{t('home.readMore')}</a>
                        </div>
                    </div>
                </div>
                {/* End Promo Block */}
            </div>

            <div className="js-slide"
                 data-title="Fereba">
                {/* Promo Block */}
                <div
                    className="g-flex-centered g-height-100vh g-min-height-500--md g-bg-cover g-bg-pos-center g-bg-img-hero g-bg-black-opacity-0_5--after"
                    style={{backgroundImage: 'url(/assets/img/home-3.jpg)'}}>
                    <div className="container text-center g-z-index-1">
                        <h2 className="text-uppercase g-font-weight-700 g-font-size-22 g-font-size-36--md g-color-white g-mb-20">Cellule
                            Pour le Développement du
                            <span className="g-color-primary"> Mali</span> <br/>
                            <span className="g-color-primary"> CPD</span>
                        </h2>
                        <div>
                            <p className="g-hidden-xs-down g-max-width-645 g-color-white-opacity-0_9 mx-auto g-mb-35">{t('home.fereba.description')}</p>
                        </div>
                        <div>
                            {!props.paymentOnly &&
                            <a className="btn btn-lg u-btn-outline-white g-font-weight-600 g-font-size-13 text-uppercase g-rounded-50 mx-2 g-px-40 g-py-15 g-mb-20"
                               href="/annuaire"
                               onClick={props.goTo('/annuaire')}
                            >{t('home.directory')}</a>}
                            <a className="btn btn-lg u-btn-primary g-font-weight-700 g-font-size-12 text-uppercase g-rounded-50 mx-2 g-px-40 g-py-15 g-mb-20"
                               target={"_blank"}
                               rel="noreferrer"
                               href="https://www.cpd-mali.com/articles/ferebawelcome">{t('home.fereba.goToFereba')}</a>
                        </div>


                    </div>
                </div>
                {/* End Promo Block */}
            </div>
        </section>
    </>
}

function SearchForm(props) {
    const {formChanged} = props
    const [text, setText] = useState('');
    const [program300j, setProgram300j] = useState(false);
    const [mali, setMali] = useState(false);
    const [companyVisible, setCompanyVisible] = useState(false);
    const [availableForJob, setAvailableForJob] = useState(false);
    const [availableForMission, setAvailableForMission] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        const data = {
            text
        };
        if (program300j) {
            data.program300j = program300j;
        }
        if (mali) {
            data.mali = mali;
        }
        if (companyVisible) {
            data['settings.companyVisible'] = companyVisible;
        }
        if (availableForJob) {
            data['settings.availableForJob'] = availableForJob;
        }
        if (availableForMission) {
            data['settings.availableForMission'] = availableForMission;
        }
        console.log("data", data);
        if (typeof props.onSubmit === 'function') {
            props.onSubmit(data);
        }
    }


    const onFormChanged = useCallback(() => {
        if (typeof formChanged === 'function') {
            let value = Boolean(text) ||
                program300j ||
                mali ||
                companyVisible ||
                availableForMission ||
                availableForJob;
            console.log(value);
            formChanged(value);
        }
    }, [text, program300j,
        mali,
        companyVisible,
        availableForMission,
        availableForJob, formChanged]);

    useEffect(() => onFormChanged(), [onFormChanged])

    return <>
        {/* Search Form */}
        <form onSubmit={onSubmit}>
            {/* Search Field */}
            <div className="g-max-width-540 mx-auto g-mb-20">
                <div className="input-group">
                    <input type="text" className="form-control g-font-size-16 border-0"
                           placeholder={`${t('home.search')}...`}
                           aria-label={`${t('home.search')}...`}
                           value={text}
                           onChange={e => {
                               setText(e.target.value)
                           }}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-primary g-font-size-18 g-py-12 g-px-25" type="submit">
                            <i className="fa fa-search"/>
                        </button>
                    </div>
                </div>
            </div>
            {/* End Search Field */}

            {/* Checkboxes */}
            <div className="g-font-size-15">
                {/* Label */}
                <label className="form-check-inline u-check g-color-white g-pl-25">
                    <input className="g-hidden-xs-up" type="checkbox"
                           checked={program300j}
                           value={program300j}
                           onChange={e => {
                               setProgram300j(e.target.checked)
                           }}/>
                    <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                        <i className="g-brd-white g-brd-primary--checked g-bg-white g-bg-primary--checked g-transition-0_2 g-transition--ease-in g-rounded-2 fa"
                           data-check-icon=""/>
                    </div>
                    Programme 300
                </label>
                {/* End Label */}

                {/* Label */}
                <label className="form-check-inline u-check g-color-white g-pl-25 g-ml-20--md">
                    <input className="g-hidden-xs-up" type="checkbox"
                           checked={mali}
                           value={mali}
                           onChange={e => {
                               setMali(e.target.checked)
                           }}/>
                    <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                        <i className="g-brd-white g-brd-primary--checked g-bg-white g-bg-primary--checked g-transition-0_2 g-transition--ease-in g-rounded-2 fa"
                           data-check-icon=""/>
                    </div>
                    {t('home.Return to Mali')}
                </label>
                {/* End Label */}
            </div>
            {/* End Checkboxes */}
            {/* Checkboxes */}
            <div className="g-font-size-15">
                {/* Label */}
                <label className="form-check-inline u-check g-color-white g-pl-25 g-ml-20--md">
                    <input className="g-hidden-xs-up" type="checkbox"
                           checked={companyVisible}
                           value={companyVisible}
                           onChange={e => {
                               setCompanyVisible(e.target.checked)
                           }}/>
                    <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                        <i className="g-brd-white g-brd-primary--checked g-bg-white g-bg-primary--checked g-transition-0_2 g-transition--ease-in g-rounded-2 fa"
                           data-check-icon=""/>
                    </div>
                    {t('profile.settings.companyVisible')}
                </label>
                {/* End Label */}
                {/* Label */}
                <label className="form-check-inline u-check g-color-white g-pl-25 g-ml-20--md">
                    <input className="g-hidden-xs-up" type="checkbox"
                           checked={availableForJob}
                           value={availableForJob}
                           onChange={e => {
                               setAvailableForJob(e.target.checked)
                           }}/>
                    <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                        <i className="g-brd-white g-brd-primary--checked g-bg-white g-bg-primary--checked g-transition-0_2 g-transition--ease-in g-rounded-2 fa"
                           data-check-icon=""/>
                    </div>
                    {t('profile.settings.availableForJob')}
                </label>
                {/* End Label */}
                {/* Label */}
                <label className="form-check-inline u-check g-color-white g-pl-25 g-ml-20--md">
                    <input className="g-hidden-xs-up" type="checkbox"
                           checked={availableForMission}
                           value={availableForMission}
                           onChange={e => {
                               setAvailableForMission(e.target.checked)
                           }}/>
                    <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                        <i className="g-brd-white g-brd-primary--checked g-bg-white g-bg-primary--checked g-transition-0_2 g-transition--ease-in g-rounded-2 fa"
                           data-check-icon=""/>
                    </div>
                    {t('profile.settings.availableForMission')}
                </label>
                {/* End Label */}
            </div>
            {/* End Checkboxes */}
        </form>
        {/* End Search Form */}
    </>
}

function Stats(props) {
    const options = {
        legend: {
            display: false
        },
        plugins: {
            labels: {
                render: (args) => {
                    return t(args.label)
                },
                position: 'outside'
            }
        }
    }

    /*const modifiedProfileParser = (result) => {
        return result;
    }*/

    return <>
        <UnifyMain id={"stats"}>
            <div className="row">
                <div className={"col-md-12 text-center"}>
                    <StatItem path={`/api/aggregate/skill/list?page=-1&select=title,value`}
                              label={t('Skills')}
                              labelKey={[(lang) => lang.title]}
                              type={"line"}
                              fill={true}
                              options={{
                                  legend: {
                                      display: true
                                  },
                                  plugins: {
                                      labels: {
                                          render: (args) => {
                                              return t(args.label)
                                          },
                                          position: 'outside'
                                      }
                                  }
                              }}
                    />
                </div>
                <div className={"col-md-12 text-center"}>
                    <StatItem path={`/api/aggregate/language/list?page=-1&select=title,level,value`}
                              label={t('Languages')}
                              type={"bar"}
                              labelKey={[(lang) => lang.title, (lang) => t(lang.level)]}
                              options={{
                                  ...options, legend: {
                                      display: true
                                  },
                                  plugins: {
                                      labels: {
                                          render: (args) => {
                                              return args.value
                                          },
                                          position: 'outside'
                                      }
                                  }
                              }}
                    />
                </div>
                <div className={"col-md-12 text-center g-mt-40--md"}>
                    <StatItem path={`/api/aggregate/experience/list?page=-1&select=sector,value`}
                              label={t('Sectors')}
                              labelKey={"sector"}
                              type={"pie"}
                              options={options}
                    />
                </div>
                <div className={"col-md-12 text-center g-mt-60--md"}>
                    <StatItem path={`/api/aggregate/profile/list?page=-1&select=value,modifyDate`}
                              label={t('Last Modification')}
                              onClick={(e) => console.log(e)}
                              type={"bar"}
                              position={"bottom"}
                              labelKey={[(item) => moment(item.modifyDate, YYYY_MM_DD).format(t('dateFormat'))]}
                              fill={false}
                              options={{
                                  ...options, plugins: {
                                      labels: {
                                          render: (args) => {
                                              return args.value
                                          },
                                          position: 'outside'
                                      }
                                  }
                              }}
                    />
                </div>
            </div>
        </UnifyMain>
    </>
}

export default function Home(props) {
    const [loaded, setLoaded] = useState(false);
    const history = useHistory();
    useEffect(() => {
        initAnimation($);
    });

    useEffect(() => {
        if (!loaded) {
            $.HSCore.components.HSCarousel.init('.js-carousel');
            setLoaded(true);
        }
    }, [loaded]);

    const onSubmit = (data) => {
        let query = queryParams(data);
        history.push(`/annuaire?${query}`);
    }

    document.title = 'CPD : Cellule Pour le Développement';

    return <>
        <MainBanner {...props} goTo={(path) => goTo(history, path)} onSubmit={onSubmit}/>
        <Stats/>
    </>
}