import React, {useState} from "react";
import GoogleButton from "./GoogleButton";
import {overlay, post} from "../utils";
import axios from 'axios'
import Alert from "./Alert";

const {$} = window;

export default function LoginForm(props) {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState(null);
    const [success, setSuccess] = useState(null);

    const setSuccessMessage = (message) => {
        setSuccess(true);
        setMessage(message);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (username.trim() === "" || password.trim() === "") {
            setMessage("All fields are required")
            return
        }
        setMessage(null);
        setLoading(true)
        let credentials = {username, password};
        console.log('credentials', credentials);
        post('/api/auth', credentials, {
            'Content-Type': 'application/json'
        }).then(res => {
            let headers = res.headers;
            let data = res.data;
            console.log("headers, data", headers, data);
            setLoading(false);
            if (data.code && data.code / 100 !== 2) {
                setMessage(data.message)
            } else {
                props.onLoginSuccess({data, headers});
            }
        })
            .catch(error => {
                console.log('error', error);
                setLoading(false);
                setMessage(error.response?.data?.message);
            });
    }

    const sendLink = async (e) => {
        e.preventDefault();
        if (!username) {
            setMessage("L'adresse email est obligatoire.");
            return;
        }
        overlay($, true);
        try {
            const {data} = await axios.post('/api/auth', {
                username: username,
                grantType: "email",
            });
            console.log("connectionStatus.data", data);
            setSuccessMessage(data.message)
            // FIXME : Add an alert.
        } catch (e) {
            let {data} = e.response;
            console.error(e.response);
            setMessage(data.message || data.error_description);
        } finally {
            overlay($, false);
        }
    };

    const createAnonymousAccount = async (e) => {
        let pwd = Math.random().toString(36);
        console.log('pwd', pwd);
        e.preventDefault();
        const id = Math.round(1000000 * Math.random())
        const user = {
            firstName: 'Prenom ' + id,
            lastName: 'Nom ' + id,
            username: `cpdien-${id}@cpd-mali.com`,
            internalEmail: `prenom_${id}.nom_${id}@cpd-mali.com`,
            password: pwd,
            anonymous: true
        }
        try {
            setLoading(true)
            const {data, headers} = await post('/api/auth/register', {user, confirmPassword: pwd});
            props.onLoginSuccess({data, headers});
        } catch (e) {
            let {data} = e.response;
            console.error(e.response);
            setMessage(data.message || data.error_description);
        } finally {
            setLoading(false);
            overlay($, false);
        }
    }

    const googleOpts = {
        'prompt': 'select_account',
        'scope': props.scope,
        'width': 'auto',
        'height': 50,
        'longtitle': true,
        'theme': 'dark',
        'onsuccess': props.onSuccess,
        'onfailure': props.onFailure,
        gapi: props.gapi
    };


    return <>
        <section className="container g-py-50">
            <div className="row justify-content-center">
                <div className="col-sm-8 col-lg-6">
                    <div className="g-brd-around g-brd-gray-light-v4 rounded g-py-40 g-px-30">
                        <header className="text-center mb-4">
                            <h2 className="h2 g-color-black g-font-weight-600">Login</h2>
                        </header>
                        <Alert message={message} success={success}/>
                        {/* Form */}
                        <form className="g-py-15" onSubmit={onSubmit}>
                            <div className="mb-4">
                                <div className="input-group g-brd-primary--focus">
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text g-width-45 g-brd-right-none g-brd-gray-light-v4 g-color-gray-dark-v5"><i
                                            className="icon-finance-067 u-line-icon-pro"/></span>
                                    </div>
                                    <input
                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-py-15 g-px-15"
                                        type="email"
                                        required
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder="john.doe@cpd-mali.com"/>
                                </div>
                            </div>

                            <div className="g-mb-35">
                                <div className="input-group g-brd-primary--focus mb-4">
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text g-width-45 g-brd-right-none g-brd-gray-light-v4 g-color-gray-dark-v5"><i
                                            className="icon-media-094 u-line-icon-pro"/></span>
                                    </div>
                                    <input
                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-py-15 g-px-15"
                                        type="password"
                                        required
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Password"/>
                                </div>
                            </div>

                            <button className="btn btn-md btn-block u-btn-primary g-py-13"
                                    type="submit"
                                    disabled={loading}
                                    onClick={onSubmit}>
                                {loading ? "Sending..." : "Login"}
                            </button>

                            <div className="d-flex justify-content-center text-center">
                                <div
                                    className="d-inline-block align-self-center g-width-50 g-height-1 g-bg-gray-light-v1"/>
                                <span className="align-self-center g-color-gray-dark-v3 mx-4">OU</span>
                                <div
                                    className="d-inline-block align-self-center g-width-50 g-height-1 g-bg-gray-light-v1"/>
                            </div>
                            <div className="g-mb-10">
                                <button className="btn btn-md btn-block u-btn-primary g-py-13"
                                        type="button"
                                        disabled={loading}
                                        onClick={sendLink}>
                                    {loading ? "Sending..." : "M'envoyer un lien de connexion"}
                                </button>
                            </div>
                            {props.paymentOnly && <>
                                <div className="d-flex justify-content-center text-center">
                                    <div
                                        className="d-inline-block align-self-center g-width-50 g-height-1 g-bg-gray-light-v1"/>
                                    <span className="align-self-center g-color-gray-dark-v3 mx-4">OU</span>
                                    <div
                                        className="d-inline-block align-self-center g-width-50 g-height-1 g-bg-gray-light-v1"/>
                                </div>
                                <div className="g-mb-10">
                                    <button className="btn btn-md btn-block u-btn-black g-py-13"
                                            type="button"
                                            disabled={loading}
                                            onClick={createAnonymousAccount}>
                                        {loading ? "Sending..." : "Incognito"}
                                    </button>
                                </div>
                            </>}
                            <div className="d-flex justify-content-center text-center g-mb-10">
                                <div
                                    className="d-inline-block align-self-center g-width-50 g-height-1 g-bg-gray-light-v1"/>
                                <span
                                    className="align-self-center g-color-gray-dark-v3 mx-4">OU avec @cpd-mali.com</span>
                                <div
                                    className="d-inline-block align-self-center g-width-50 g-height-1 g-bg-gray-light-v1"/>
                            </div>
                            <GoogleButton {...googleOpts}/>
                        </form>
                        {/* End Form */}

                        <footer className="text-center">
                            <p className="g-color-gray-dark-v5 g-font-size-13 mb-0">Les comptes @cpd-mali.com sont des
                                comptes Google.</p>
                        </footer>
                    </div>
                </div>
            </div>
        </section>
    </>

}