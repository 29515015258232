import React from "react";
import {CKFileUploadAdapterPlugin} from "../CKFileUploadAdapter";
import {get, goToTop, overlay, post, put} from "../utils";
import UnifyMain from "./UnifyMain";

import moment from "moment";
import t from "../i18n/translator";
import DateRange from "./DateRange";

const {$, DecoupledEditor: CKEditor} = window;

const DATE_FORMAT = t('dateFormat');

export default class PaymentSessionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            title: '',
            amount: '',
            pool: false,
            startDate: '',
            endDate: '',
            description: '',
            loading: false,
            message: '',
            messageColor: 'green'
        };
        this.editor = null;
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange(event) {
        let type = event.target.type;
        let name = event.target.name;
        let value = event.target.value;
        if (type === 'checkbox') {
            value = event.target.checked;
        }
        if (name === 'startDate' || name === 'endDate') {
            let date = moment(value, DATE_FORMAT);
            if (date.isValid()) {
                value = date.format('YYYY-MM-DD');
            }
        }
        this.setState({
            [name]: value
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.description !== this.state.description) {
            this.setHtmlEditor();
        }
        if (prevState.loading !== this.state.loading) {
            overlay($, this.state.loading);
        }
    }

    setHtmlEditor() {
        if (!this.editor) {
            let inputEditor = document.querySelector('#description');
            if (inputEditor) {
                CKEditor
                    .create(inputEditor, {
                        extraPlugins: [CKFileUploadAdapterPlugin]
                    })
                    .then(editor => {
                        this.editor = editor;
                        /*this.editor.model.document.on('change:data', (e, property, newValue, oldValue) => {
                            let description = this.editor.getData();
                        });*/
                        const toolbarContainer = document.querySelector('#description-toolbar-container');
                        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
    }

    componentDidMount() {
        this.loadSession();
    }

    loadSession() {
        const id = this.props.match.params.id;
        if (id && id !== 'new') {
            try {
                this.setState({
                    loading: true
                }, async () => {
                    const {
                        data: {
                            title,
                            amount,
                            startDate,
                            endDate,
                            description,
                            pool
                        }
                    } = await get(`/api/session/${id}`);
                    this.setState({
                        title,
                        id,
                        description,
                        pool,
                        loading: false,
                        amount: amount / 100,
                        startDate: startDate,
                        endDate: endDate,
                    });
                    document.title = 'CPD, Edit : ' + title;
                })

            } catch (e) {
                console.error('error', e);
            }
        } else if (id === 'new') {
            this.setHtmlEditor();
        }
    }

    onSubmit(e) {
        e.preventDefault();
        let {
            title,
            amount,
            pool,
            startDate,
            endDate,
            description
        } = this.state;

        let data = {
            title,
            amount,
            pool,
            startDate,
            endDate,
            description
        };
        if (this.editor.getData()) {
            data.description = this.editor.getData();
        }
        let func;
        let path = '/api/session';
        data.amount = data.amount * 100;
        data.startDate = moment(data.startDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss');
        data.endDate = moment(data.endDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss');
        const id = this.props.match.params.id;
        if (id === 'new') {
            func = post;
        } else {
            data.id = this.state.id * 1;
            func = put;
        }
        this.setState({
            loading: true
        }, () => func(path, data)
            .then(response => {
                this.setState({
                    loading: false,
                    id: response.data.id
                });
                this.setState({
                    message: 'Mise à jour effectuée',
                    messageColor: 'green'
                });
                goToTop($);
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                this.setState({
                    message: error.message,
                    messageColor: 'red'
                });
                goToTop($);
            }));
    }

    render() {
        return <UnifyMain>
            <form className="g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-30"
                  onSubmit={this.onSubmit}>
                {Boolean(this.state.message) &&
                    <div className={`alert alert-dismissible fade show g-bg-${this.state.messageColor} rounded-0`}
                         role="alert">
                        <button type="button" className="close u-alert-close--light" data-dismiss="alert"
                                aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>

                        <div className="media">
                                <span className="d-flex g-mr-10 g-mt-5">
                                  <i className="icon-info g-font-size-25"/>
                                </span>
                            <span className="media-body align-self-center">
                                    <strong>Information!</strong> {this.state.message}
                                </span>
                        </div>
                    </div>}
                {/* Title Input */}
                <div className="form-group g-mb-20">
                    <label className="g-mb-10" htmlFor="inputGroup1_1">{t('payment.session.Title')}</label>
                    <input id="title"
                           className="form-control form-control-md rounded-0"
                           type="text"
                           placeholder="Campagne de cotisation de la CPD"
                           name={"title"}
                           required
                           value={this.state.title}
                           onChange={this.handleChange}
                    />
                </div>
                {/* End Title Input */}
                {/* Amount Input */}
                <div className="form-group g-mb-20">
                    <label className="g-mb-10" htmlFor="amount">{t('payment.session.Amount')}</label>
                    <div className="input-group g-brd-primary--focus">
                        <input id="amount"
                               className="form-control form-control-md rounded-0"
                               min={5}
                               step={0.01}
                               type="number"
                               placeholder="50 €"
                               name={"amount"}
                               required
                               value={this.state.amount}
                               onChange={this.handleChange}
                        />
                        <div className="input-group-append">
                            <div
                                className="input-group-text justify-content-center g-width-55 g-font-size-16 g-color-gray g-bg-gray-light-v5 rounded-0">€
                            </div>
                        </div>
                    </div>
                    <small className="form-text text-muted g-font-size-default g-mt-10">
                        <strong>Note:</strong> {t('payment.session.AmountNote')}
                    </small>
                </div>
                {/* End Amount Input */}
                {/* Pool Input */}
                <div className="form-group g-mb-20">
                    <label className="g-mb-10" htmlFor="pool">{t('payment.session.Pool')}
                        <input id="pool"
                               className="form-control form-control-md rounded-0"
                               type="checkbox"
                               title="Est-ce une cagnotte?"
                               name={"pool"}
                               checked={this.state.pool}
                               onChange={this.handleChange}
                        />
                    </label>
                </div>
                {/* End Pool Input */}
                {/* Select Date Range */}
                <div className="form-group g-mb-20">
                    <label className="g-mb-10">{t('payment.session.Period selection')}</label>
                    <DateRange fromId={'startDate'}
                               toId={'endDate'}
                               formLabel={t('payment.session.Start date')}
                               toLabel={t('payment.session.End date')}
                               startDate={this.state.startDate}
                               endDate={this.state.endDate}
                               readOnly={false}
                               range={true}
                               onChange={this.handleChange}/>
                </div>
                <div className="form-group g-mb-20">
                    <label className="g-mb-10" htmlFor="description">{t('payment.session.Description')}</label>
                    <div id="description-toolbar-container"/>
                    <div id="description" dangerouslySetInnerHTML={{__html: this.state.description}}/>
                </div>
                <button className={"btn btn-primary"}>{t('Save')}</button>
            </form>
        </UnifyMain>;
    }
}