import React, {useEffect, useState} from "react";

import logo from "../logo.png";
import {useHistory} from "react-router-dom";
import {useGoogleLogout} from "react-google-login";
import {initHeader} from "../utils";
import Translator from "../i18n/translator";

const {$} = window;

function getMenus(props) {
    return [
        {
            key: "annuaire",
            title: Translator({key: "header.directory"}),
            link: "/annuaire",
            hide: props.paymentOnly
        },
        {
            key: "payment",
            title: Translator({key: "header.payment"}),
            link: "/payment",
        }
    ];
}

export default function UnifyHeader(props) {
    const history = useHistory();
    const [selectedMenu, setSelectedMenu] = useState(props.selectedMenu || '/');

    const goTo = (path) => {
        return (e) => {
            e.preventDefault();
            setSelectedMenu(path);
            history.push(path);
        };
    }

    const generateMenus = (menus, parent) => {
        let opts = {};
        if (parent) {
            opts["id"] = parent.controls;
            opts["aria-labelledby"] = parent.id;
            opts["className"] =
                "hs-sub-menu list-unstyled g-text-transform-none g-brd-top g-brd-primary g-brd-top-2 g-min-width-200 g-mt-20 g-mt-10--lg--scrolling";
        } else {
            opts["className"] = "navbar-nav text-uppercase g-font-weight-600 ml-auto";
        }

        const getOnClick = (menu) => {
            return (e) => {
                goTo(menu.link)(e);
                menu.action ? menu.action(e) : history.push(menu.link)
            };
        }

        return (
            <ul {...opts}>
                {menus
                    .filter((menu) => !menu.hide)
                    .map((menu) => {
                        let key = `menu-${parent ? parent.key : "root"}-${menu.key}`;
                        let hasSubmenus = (menu.submenus && menu.submenus.length > 0) || menu.hasSubmenus;
                        return (
                            <li
                                key={key}
                                className={`${parent ? "dropdown-item" : "nav-item"} ${
                                    (menu.link && selectedMenu.includes(menu.link)) ? "active" : ""
                                } ${hasSubmenus ? "hs-has-sub-menu " : ""} g-mx-20--lg`}
                                data-animation-in="fadeIn"
                                data-animation-out="fadeOut"
                            >
                                {menu.body ? (
                                    menu.body
                                ) : hasSubmenus ? (
                                    <>
                                        <a
                                            id={parent.id}
                                            className="nav-link g-px-0"
                                            href={menu.link || "/#"}
                                            aria-expanded="false"
                                            aria-controls={menu.controls}
                                            onClick={getOnClick(menu)}
                                        >
                                            {menu.title}
                                        </a>
                                        {generateMenus(menu.submenus, menu)}
                                    </>
                                ) : (
                                    <a
                                        href={menu.link || "/#"}
                                        onClick={getOnClick(menu)}
                                        className="nav-link g-px-0"
                                    >
                                        {menu.title}
                                        {menu.link === selectedMenu && <span className="sr-only">(current)</span>}
                                    </a>
                                )}
                            </li>
                        );
                    })}
            </ul>
        );
    }

    const googleLogout = useGoogleLogout({
        clientId: props.clientId,
        onLogoutSuccess: (res) => console.log('Logged Out...', res),
        onFailure: () => console.error('Failure...')
    });

    const doLogout = (onLoggedOut) => {
        return (e) => {
            e.preventDefault();
            googleLogout.signOut();
            fetch('/api/auth', {
                method: 'DELETE'
            })
                .then(res => onLoggedOut())
                .catch(err => onLoggedOut());
        };
    }

    const getRightMenus = ({isConnected, fullName, onLoggedOut}) => {
        return [
            {
                key: 'login',
                title: Translator({key: "header.login"}),
                link: '/login',
                hide: isConnected
            },
            {
                key: 'me',
                title: fullName,
                link: '/profile',
                id: "nav-link-profile",
                controles: "nav-submenu-profile",
                hide: !isConnected,
                hasSubmenus: true,
                body: <>
                    <a id="nav-link--features"
                       className="nav-link g-py-7 g-px-0"
                       href="/#"
                       onClick={(e) => e.preventDefault()}
                       aria-expanded="false"
                       aria-controls="nav-submenu--features">{fullName}</a>

                    <ul className="hs-sub-menu list-unstyled u-shadow-v11 g-brd-top g-brd-primary g-brd-top-2 g-min-width-220 g-mt-18 g-mt-8--lg--scrolling"
                        id="nav-submenu--features" aria-labelledby="nav-link--features">
                        {/* Features - Headers */}
                        <li className="dropdown-item ">
                            <a className="nav-link"
                               href="/profile"
                               onClick={goTo('/profile')}>{Translator({key: "header.profile"})}</a>
                        </li>
                        {/* End Features - Headers */}

                        {/* Features - Promo blocks */}
                        <li className="dropdown-item ">
                            <a className="nav-link" href="/logout"
                               onClick={doLogout(onLoggedOut)}
                            >{Translator({key: "header.logout"})}</a>
                        </li>
                        {/* End Features - Promo blocks */}
                    </ul>
                </>
            }
        ];
    }

    const menus = getMenus(props);
    const rightMenus = getRightMenus(props);

    useEffect(() => {
        initHeader($);
    });

    return (
        <header
            id="js-header"
            className="u-header u-header--static u-header--show-hide u-header--change-appearance"
            data-header-fix-moment="300"
            data-header-fix-effect="slide"
        >
            <div
                className="u-header__section u-header__section--light g-bg-white g-transition-0_3 g-py-10"
                data-header-fix-moment-exclude="g-bg-white g-py-10"
                data-header-fix-moment-classes="g-bg-white-opacity-0_8 u-shadow-v18 g-py-0">
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <button
                            className="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-right-0 g-top-3"
                            type="button"
                            aria-label="Toggle navigation"
                            aria-expanded="false"
                            aria-controls="navBar"
                            data-toggle="collapse"
                            data-target="#navBar"
                        >
              <span className="hamburger hamburger--slider">
                <span className="hamburger-box">
                  <span className="hamburger-inner"/>
                </span>
              </span>
                        </button>

                        <a href="/" className="navbar-brand d-flex" onClick={goTo('/')}>
                            <img src={logo} alt={"CPD Annuaire"}/>
                        </a>

                        <div
                            className="js-mega-menu collapse navbar-collapse align-items-center flex-sm-row g-pt-10 g-pt-5--lg"
                            id="navBar"
                        >
                            {props.isConnected && generateMenus(menus, false)}
                            {generateMenus(rightMenus, false)}
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
}
