import React, {useEffect} from "react";

const {gapi} = window;

export default function GoogleButton({id, ...props}) {
    const buttonId = props.id || "google-button";



    useEffect(() => {
        console.log('useEffect::gapi', gapi);
        let googleApi = gapi || props.gapi;
        if(googleApi) {
            googleApi.signin2.render(buttonId, {
                ...props
            });
        }
    })
    return <div id={buttonId}/>
}