import {Route, Switch, useLocation} from "react-router-dom";
import UnifyHeader from "./components/UnifyHeader";
import UnifyMain from "./components/UnifyMain";
import UnifyFooter from "./components/UnifyFooter";
import UnifyCopyright from "./components/UnifyCopyright";
import UnifyGoToTop from "./components/UnifyGoToTop";
import Login from "./components/Login";
import { useEffect, useState } from "react";
import Home from "./components/Home";
import PaymentForm from "./components/PaymentForm";
import { get, has, parseQueryString, removeToken } from "./utils";
import PaymentSession from "./components/PaymentSession";
import ProfileView from "./components/ProfileView";
import UserList from "./components/UserList";
import PaymentSessionForm from "./components/PaymentSessionForm";
import AuthorizationCode from "./components/AuthorizationCode"
import DDAlert  from './alert'

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID ||
    "104745960335-il7maaokho1t5c34fqm8b8kad3ucqllc.apps.googleusercontent.com";

const SCOPE = process.env.REACT_APP_SCOPE ||
    "email profile https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.send";

const PAYMENT_ONLY = process.env.REACT_APP_PAYMENT === "true";

export default function App(props) {
    const {pathname} = useLocation();
    const [isConnected, setIsConnected] = useState(false);
    const [fullName, setFullName] = useState('');
    const [userId, setUserId] = useState('');
    const [roles, setRoles] = useState([]);
    const [profileUrl, setProfileUrl] = useState(null);
    const [googleApi, setGoogleApi] = useState(null);

    const onLoggedIn = (response) => {
        console.log('response', response);
        const {data} = response;
        if (data.code && data.code / 100 >= 4) {
            setIsConnected(false);
        } else {
            setIsConnected(true);
            setFullName(data.name);
            setUserId(data.id);
            setProfileUrl(data.profileUrl);
            setRoles(data.roles);
        }
    };

    const onLoggedOut = () => {
        removeToken();
        setIsConnected(false);
    };

    const onGoogleSuccess = (googleUser) => {
        console.log('googleUser', googleUser);
    };

    window.addEventListener('onGoogleReady', ({detail}) => {
        setGoogleApi(detail.gapi);
    });

    useEffect(() => {
        if (!isConnected) {
            get('/api/auth')
                .then((response) => {
                    const {data} = response;
                    if (data.code && data.code / 100 !== 2) {
                        console.log('data', data);
                    } else {
                        onLoggedIn(response);
                    }
                })
                .catch(err => {
                    console.error('err', err);
                    setIsConnected(false);
                });
        }
    }, [isConnected]);

    const queries = parseQueryString();

    const canEdit = has(roles, ['ADMIN', 'DIRECTION']);
    return (
        <main>
            <UnifyHeader
                fullName={fullName}
                isConnected={isConnected}
                clientId={CLIENT_ID}
                onLoggedOut={onLoggedOut}
                selectedMenu={pathname}
                paymentOnly={PAYMENT_ONLY}
            />
            <DDAlert show={false}/>
            <Switch>
                {isConnected ? <>
                    <Route path="/" exact>
                        <Home paymentOnly={PAYMENT_ONLY}/>
                    </Route>
                    {!PAYMENT_ONLY &&
                    <Route path="/annuaire" exact>
                        <UnifyMain>
                            <UserList/>
                        </UnifyMain>
                    </Route>}
                    <Route path={PAYMENT_ONLY ? "/profile" : "/profile/:id?"} exact>
                        <UnifyMain>
                            <ProfileView userId={userId} name={fullName} userImage={profileUrl}
                                         profileUrl={profileUrl}/>
                        </UnifyMain>
                    </Route>
                    <Route path="/payment" exact>
                        <UnifyMain>
                            <PaymentSession userId={userId} canEdit={canEdit}/>
                        </UnifyMain>
                    </Route>
                    {canEdit &&
                    <Route path="/payment/session/:id?" exact component={PaymentSessionForm}/>}
                    <Route path="/payment/:sessionId" exact>
                        <UnifyMain>
                            <PaymentForm userId={userId} amount={queries.amount} name={fullName} canEdit={canEdit} paymentOnly={PAYMENT_ONLY}/>
                        </UnifyMain>
                    </Route>
                    <Route path="/pool/:sessionId" exact>
                        <UnifyMain>
                            <PaymentForm pool={true} userId={userId} name={fullName} canEdit={canEdit} amount={queries.amount} paymentOnly={PAYMENT_ONLY}/>
                        </UnifyMain>
                    </Route>
                </> : <>
                    <Route exact path="/authorize/:token">
                        <AuthorizationCode onLoggedIn={onLoggedIn}/>
                    </Route>
                    <Route path="/pool/:sessionId" exact>
                        <UnifyMain>
                            <PaymentForm userId={userId} pool={true} amount={queries.amount} paymentOnly={PAYMENT_ONLY}/>
                        </UnifyMain>
                    </Route>
                    <Route component={(props) =>
                        new Login({
                            ...props,
                            clientId: CLIENT_ID,
                            scope: SCOPE,
                            onLoggedIn,
                            onGoogleSuccess,
                            gapi: googleApi,
                            paymentOnly: PAYMENT_ONLY
                        })
                    }
                    />
                </>}
            </Switch>
            <UnifyFooter/>
            <UnifyCopyright/>
            <UnifyGoToTop/>
        </main>
    );
}