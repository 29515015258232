import React from "react";


export default function UnifyCopyright(props) {
    return (
        <>
            {/*<!-- Copyright Footer -->*/}
            <footer className="g-bg-gray-dark-v1 g-color-white-opacity-0_8 g-py-20">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 text-center text-md-left g-mb-10 g-mb-0--md">
                            <div className="d-lg-flex">
                                <small className="d-block g-font-size-default g-mr-10 g-mb-10 g-mb-0--md">CPD 2021 © Tous
                                    droits reservés.</small>
                                <ul className="u-list-inline">
                                    <li className="list-inline-item">
                                        <a className="g-color-white-opacity-0_8 g-color-white--hover" href="/#">Privacy
                                            Policy</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <span>|</span>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="g-color-white-opacity-0_8 g-color-white--hover" href="/#">Terms of
                                            Use</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <span>|</span>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="g-color-white-opacity-0_8 g-color-white--hover"
                                           href="/#">License</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <span>|</span>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="g-color-white-opacity-0_8 g-color-white--hover"
                                           href="/#">Support</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-4 align-self-center">
                            <ul className="list-inline text-center text-md-right mb-0">
                                <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top"
                                    title="" data-original-title="Facebook">
                                    <a href="https://www.facebook.com/cpdmali"
                                       rel="noreferrer"
                                       target={"_blank"}
                                       className="g-color-white-opacity-0_5 g-color-white--hover">
                                        <i className="fa fa-facebook"/>
                                    </a>
                                </li>
                                <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top"
                                    title="" data-original-title="Twitter">
                                    <a href="https://twitter.com/CPDMali"
                                       rel="noreferrer"
                                       target={"_blank"} className="g-color-white-opacity-0_5 g-color-white--hover">
                                        <i className="fa fa-twitter"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            {/*<!-- End Copyright Footer -->*/}
        </>
    )
}