import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import axios from "axios";
import {overlay, storeToken} from "../utils";
import Alert from "./Alert";

const {$, parseQueryString} = window;

// /authorize/token
export default function AuthorizationCode(props) {
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const {token} = useParams();
    let history = useHistory();
    const search = useLocation().search;
    const queries = parseQueryString(search);
    const {confirmToken} = queries;

    useEffect(() => {
        const goToHome = (response) => {
            overlay($, false);
            storeToken(response.headers);
            props.onLoggedIn(response);
            history.push('/');
        }

        const displayError = (error) => {
            overlay($, false);
            console.log(error.response);
            setSuccess(false);
            setMessage(error.response?.data?.message);
        }
        overlay($, true);
        axios.post('/api/auth', {
            grantType: "authorization_code"
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            if (confirmToken) {
                axios.post('/api/auth', {
                    grantType: 'confirmToken',
                    confirmToken
                }).then(goToHome, displayError);
            } else {
                goToHome(res);
            }
        }, displayError);
    });

    return Boolean(message) && (<div>
        <Alert message={message} success={success}/>
    </div>)
}