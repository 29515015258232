import React from "react";

export default function UnifyMain(props) {
    return (
        <section className="g-py-50">
            <div className="container">

                {props.children}

            </div>
        </section>
    )
}