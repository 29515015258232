import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";

import truncate from "truncate-html";
import {useHistory, useParams} from "react-router-dom";
import {
    fontSizeComputer,
    get,
    getCountries,
    getScoreColor,
    getScoreImage,
    getSkillColors,
    goTo,
    goToTop,
    initCharts,
    overlay,
    post,
    put,
    randomColor,
    remove,
    storeToken
} from "../utils";
import moment from "moment";
import Nl2Br from "./nl2br";
import Alert from "./Alert";

import FileField from "./FileField";
import FieldSelect from "./FieldSelect";

import DateRange, {YYYY_MM_DD} from "./DateRange"
import t from "../i18n/translator";

const {$} = window;

function UserImage(props) {
    const [message, setMessage] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleError = (event) => {
        let response = event.response;
        setMessage(response?.data?.message);
        setSuccess(false);
    }

    const handleSuccess = (event) => {
        let response = event.response;
        setMessage(response?.data?.message);
        setSuccess(true);
    }

    return <>
        <Alert message={message} success={success}/>
        {/* User Image */}
        <div className="u-block-hover g-pos-rel">
            <figure>
                <img className="img-fluid w-100 u-block-hover__main--zoom-v1"
                     src={Boolean(props.user?.profileUrl) ? props.user?.profileUrl : `https://via.placeholder.com/400x450.jpg?text=${props.name}`}
                     alt="Profile Preview"/>
            </figure>

            {/* Figure Caption */}
            <figcaption className="u-block-hover__additional--fade g-bg-black-opacity-0_5 g-pa-30">
                <div
                    className="u-block-hover__additional--fade u-block-hover__additional--fade-up g-flex-middle">
                    {/* Figure Social Icons */}
                    <ul className="list-inline text-center g-flex-middle-item--bottom g-mb-20">
                        <li className="list-inline-item align-middle g-mx-7">
                            <FileField fieldId={"profileField"}
                                       name={"file"}
                                       accept={'image/*'}
                                       onError={handleError}
                                       onSuccess={handleSuccess}
                                       label={t({key: 'profile.changePicture'})}
                                       params={{userId: props.user?.id}}
                                       onChange={props.onImageChanged}/>
                            {/*<a className="u-icon-v1 u-icon-size--md g-color-white"
                               onClick={e => {
                                   e.preventDefault();
                                   return false;
                               }}
                               href="/#">
                                <i className="icon-note u-line-icon-pro"/>
                            </a>*/}
                        </li>
                        {/*<li className="list-inline-item align-middle g-mx-7">
                            <a className="u-icon-v1 u-icon-size--md g-color-white"
                               onClick={e => {
                                   e.preventDefault();
                                   return false;
                               }}
                               href="/#">
                                <i className="icon-notebook u-line-icon-pro"/>
                            </a>
                        </li>
                        <li className="list-inline-item align-middle g-mx-7">
                            <a className="u-icon-v1 u-icon-size--md g-color-white"
                               onClick={e => {
                                   e.preventDefault();
                               }}
                               href="/#">
                                <i className="icon-settings u-line-icon-pro"/>
                            </a>
                        </li>*/}
                    </ul>
                    {/* End Figure Social Icons */}
                </div>
            </figcaption>
            {/* End Figure Caption */}

            {/* User Info */}
            <span className="g-pos-abs g-top-20 g-left-0">
                  <a className="btn btn-sm u-btn-primary rounded-0"
                     onClick={e => {
                         e.preventDefault();
                     }}
                     href="/#"
                  >{props.name}</a>
                  <small className="d-block g-bg-black g-color-white g-pa-5">{props.jobTitle} at {props.company}</small>
                </span>
            {/* End User Info */}
        </div>
        {/* User Image */}
    </>
}

function ProfileImage(props) {
    let color = getScoreColor(props.score);
    const style = {
        border: `solid 5px ${color}`
    }
    return <>
        {/* User Image */}
        <div className="g-mb-20">
            <img className="img-fluid w-100" style={style}
                 src={Boolean(props.user?.profileUrl) ? props.user?.profileUrl : `https://via.placeholder.com/400x450.jpg/3498db/${color.substr(1)}?text=${props.firstName?.substr(0, 1)}${props.lastName?.substr(0, 1)}`}
                 alt="Profile Preview"/>
        </div>
        {/* User Image */}

        {/* User Contact Buttons */}
        <a className="btn btn-block u-btn-outline-primary g-rounded-50 g-py-12 g-mb-10"
           href={`mailto:${props.user?.username}`}>
            <i className="icon-envelope g-pos-rel g-top-1 g-mr-5"/> {t({key: 'userList.contactEmail'})}
        </a>
        {Boolean(props.user?.phoneNumber) && <a className="btn btn-block u-btn-darkgray g-rounded-50 g-py-12 g-mb-10"
                                                href={`tel:${props.user?.phoneNumber}`}>
            <i className="icon-phone g-pos-rel g-top-1 g-mr-5"/> {t({key: 'userList.phone'})}
        </a>}
        {/* End User Contact Buttons */}
    </>
}

export function formatAddress(address) {
    if (!address) return null
    const {line1, line2, zipCode, city, country} = address;
    let result = '';
    const append = (value, sep = ',') => {
        if (!value) return;
        if (result === '') result = value;
        else result = `${result}${sep} ${value}`
    }
    append(line1)
    append(line2)
    append(zipCode)
    append(city, '')
    append(country)
    return result;
}

function UserDetailsView(props) {
    let nameComponent = <h2 className="g-font-weight-300 g-mr-10">{props.name}</h2>;

    return <>

        {/* User Details */}
        <div className="d-flex align-items-center justify-content-sm-between g-mb-5">
            {props.list ? <a href={`/profile/${props.user?.id}`} onClick={props.goTo(`/profile/${props.user?.id}`)}>
                {nameComponent}
            </a> : nameComponent}
            <ul className="list-inline mb-0">
                {props.user?.id === props.userId && <li className="list-inline-item g-mx-2">
                    <a className="u-icon-v1 u-icon-size--sm u-icon-slide-up--hover g-color-gray-light-v1 g-bg-gray-light-v5 g-color-gray-light-v1--hover rounded-circle"
                       href="/#"
                       onClick={e => {
                           e.preventDefault();
                           if (typeof props.onEdit === "function") {
                               props.onEdit();
                           }
                       }}>
                        <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-pencil"/>
                        <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-pencil"/>
                    </a>
                </li>}
            </ul>
        </div>
        {/* End User Details */}
        <div className={"row"}>
            <div className={"col-md-10"}>
                {/* User Position */}
                <h4 className="h6 g-font-weight-300 g-mb-10">
                    <i className="icon-badge g-pos-rel g-top-1 g-mr-5 g-color-gray-dark-v5"/>
                    <a className="g-color-main g-color-primary--hover"
                       onClick={e => {
                           e.preventDefault();
                           if (!Boolean(props.jobTitle)) return false;
                           props.goTo(`/annuaire?text=${props.jobTitle}`)(e);
                       }}
                       href={`/annuaire?text=${props.jobTitle}`}>{props.jobTitle || 'Aucun poste defini'}</a>
                    {Boolean(props.company) && <> at <a className="g-color-main g-color-primary--hover"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            props.goTo(`/annuaire?text=${props.company}`)(e);
                                                        }}
                                                        href={`/annuaire?text=${props.company}`}>{props.company}</a></>}
                </h4>
                {/* End User Position */}

                {/* User Info */}
                <ul className="list-inline g-font-weight-300">
                    <li className="list-inline-item g-mr-20">
                        <i className="icon-location-pin g-pos-rel g-top-1 g-color-gray-dark-v5 g-mr-5"/>
                        {Boolean(props.address?.city) && <><a className="g-color-main g-color-primary--hover"
                                                              onClick={e => {
                                                                  e.preventDefault();
                                                                  if (!props.address.city) return false;
                                                                  props.goTo(`/annuaire?text=${props.address.city}`)(e);
                                                              }}
                                                              href={`/annuaire?text=${props.address.city}`}>{formatAddress({city: props.address.city})}</a>, </>}
                        <a className="g-color-main g-color-primary--hover"
                           onClick={e => {
                               e.preventDefault();
                               if (!props.address?.country) return false;
                               props.goTo(`/annuaire?text=${props.address?.country}`)(e);
                           }}
                           href={`/annuaire?text=${props.address?.country}`}>{formatAddress({country: props.address ? props.address.country : ''}) || (!Boolean(props.address?.city) && t('Undefined'))}</a>
                    </li>
                    <li className="list-inline-item g-mr-20">
                        <i className="icon-envelope g-pos-rel g-top-1 g-color-gray-dark-v5 g-mr-5"/>
                        <a className="g-color-main g-color-primary--hover"
                           href={`mailTo:${props.user?.username}`}>{props.user?.username}</a>
                    </li>
                    <li className="list-inline-item g-mr-20">
                        <i className="icon-phone g-pos-rel g-top-1 g-color-gray-dark-v5 g-mr-5"/>
                        <a className="g-color-main g-color-primary--hover"
                           href={`tel:${props.user?.phoneNumber}`}>{props.user?.phoneNumber}</a>
                    </li>
                    <li className="list-inline-item g-mr-20">
                        <i className="icon-graduation g-pos-rel g-top-1 g-color-gray-dark-v5 g-mr-5"/>
                        <a className="g-color-main g-color-primary--hover"
                           onClick={props.goTo(`/annuaire?text=${props.user?.promotion}`)}
                           href={`/annuaire?text=${props.user?.promotion}`}>{props.user?.promotion}</a>
                    </li>
                </ul>
                {/* End User Info */}
            </div>
            {!props.list && <div className={"col-md-2"}>
                <UserBadge {...props}/>
            </div>}
        </div>
        <hr className="g-brd-gray-light-v4 g-my-20"/>

        <p className="lead g-line-height-1_8">
            {props.list ? truncate(props.description?.replaceAll('\n', '<br/>') || 'Aucune description', 50, {byWords: true}).split('<br>')
                .map((item, key) => {
                    return <Fragment key={key}>{item}<br/></Fragment>
                }) : (<Nl2Br value={props.description}/> || 'Aucune description')}
        </p>

        <hr className="g-brd-gray-light-v4 g-my-20"/>
    </>
}

function Actions(props) {
    const onSave = async (e) => {
        e.preventDefault();
        if (props.editing) {
            props.setSuccess(false);
            props.setMessage("Please save edited fields");
        } else {
            if (typeof props.onSave === "function") {
                props.onSave(props.profile);
            }
        }
        goToTop($);
    }
    return <div className="text-sm-right">
        <a className="btn u-btn-primary rounded-0 g-py-12 g-px-25"
           href={"/#"}
           onClick={(e) => onSave(e)}
        >{t({key: 'Finish'})}</a>
    </div>
}

function EditProfileField(props) {
    return <>{!props.editValue && <>
        <div className="g-pr-10">
            <strong
                className="d-block d-md-inline-block g-color-gray-dark-v2 g-width-200 g-pr-10">{props.label}</strong>
            {props.checkbox !== true
                ? <span className="align-top">{props.value}</span>
                : <div className="u-check">
                    <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0" name={props.name}
                           type="checkbox" checked={props.value} onChange={console.log} readOnly/>
                    <div className="u-check-icon-radio-v7">
                        <i className="d-inline-block"/>
                    </div>
                </div>
            }
        </div>
        <span>
                        <i className="icon-pencil g-color-gray-dark-v5 g-color-primary--hover g-cursor-pointer g-pos-rel g-top-1"
                           onClick={e => props.setEditValue(true)}/>
                      </span>
    </>}

        {props.editValue &&
        <>
            <div className="align-top form-group row g-width-100x--lg">
                <label
                    className="col-sm-2 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right">{props.label}</label>
                <div className="col-sm-10">
                    {props.autocomplete &&
                    <FieldSelect fieldId={props.path + "-" + props.name + (props.id ? ("-" + props.id) : "")}
                                 name={props.name}
                                 addButton={t({key: 'Add'})}
                                 onChange={e => props.onChange({
                                     id: props.id,
                                     [props.name]: e.target.value
                                 })}
                                 placeholder={props.placeholder || props.label}
                                 path={props.path}
                                 value={props.value}
                                 queryFilter={props.queryFilter}
                                 field={props.field || props.name}
                                 options={props.options || []}
                                 select={props.name}
                                 labelField={props.labelField || props.name}/>}
                    {!props.autocomplete && <div className="input-group g-brd-primary--focus">
                        {props.longtext && <textarea className="form-control form-control-md rounded-0 pr-0"
                                                     placeholder={props.placeholder || props.label}
                                                     value={props.value}
                                                     rows={10}
                                                     onChange={e => props.onChange({
                                                         id: props.id,
                                                         [props.name]: e.target.value
                                                     })}/>}
                        {props.checkbox === true &&
                        <label className="d-flex align-items-center justify-content-between g-pull-50x-right">
                            <div className="u-check">
                                <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0" name={props.name}
                                       type="checkbox" checked={props.value} onChange={e => props.onChange({
                                    id: props.id,
                                    [props.name]: e.target.checked
                                })}/>
                                <div className="u-check-icon-radio-v7">
                                    <i className="d-inline-block"/>
                                </div>
                            </div>
                        </label>}
                        {!(props.longtext || props.checkbox === true) &&
                        <input className="form-control form-control-md rounded-0 pr-0"
                               type="text" placeholder={props.placeholder || props.label} value={props.value}
                               onChange={e => props.onChange({
                                   id: props.id,
                                   [props.name]: e.target.value
                               })}/>}

                    </div>}
                </div>
            </div>

            <span>
                <i className="fa fa-save g-color-gray-dark-v5 g-color-primary--hover g-cursor-pointer g-pos-rel g-top-minus-5"
                   onClick={e => {
                       props.onSave(e, {id: props.id, [props.name]: props.value}, props.setEditValue)
                   }}/>
            </span>
        </>}
    </>
}

function EditProfileAddress(props) {
    const [address, setAddress] = useState(props.address)
    const [editLine1, setEditLine1] = useState(false);
    const [editZipCode, setEditZipCode] = useState(false);
    const [editCity, setEditCity] = useState(false);
    const [editCountry, setEditCountry] = useState(false);
    const [editing, setEditing] = useState(false);
    const [draft, setDraft] = useState(false);

    const options = getCountries();

    const onSave = (e, data, callback) => {
        callback(false);
        setDraft(true)
    }

    useEffect(() => {
        setEditing(editLine1 || editZipCode || editCity || editCountry);
    }, [editLine1, editZipCode, editCity, editCountry]);

    useEffect(() => {
        props.setEditValue(editing)
        if (!editing && draft) {
            console.log('Need save !!!');
            setDraft(false);
            props.onSave({
                preventDefault: () => {
                }
            }, {id: props.id, address}, props.setEditValue);
        }
    }, [editing, draft, address, props]);

    return <>
        {!editing ?
            <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                <div className="g-pr-10">
                    <strong
                        className="d-block d-md-inline-block g-color-gray-dark-v2 g-width-200 g-pr-10">{t('profile.address')}</strong>
                    <span className="align-top">{formatAddress(address)} </span>
                </div>
                <span>
                        <i className="icon-pencil g-color-gray-dark-v5 g-color-primary--hover g-cursor-pointer g-pos-rel g-top-1"
                           onClick={e => setEditing(true)}/>
                      </span>
            </li> :
            <>
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <EditProfileField editValue={editLine1}
                                      name={"line1"}
                                      label={t('profile.street')}
                                      onChange={data => setAddress({...address, ...data})}
                                      value={address?.line1}
                                      setEditValue={setEditLine1}
                                      onSave={onSave}/>
                </li>
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <EditProfileField editValue={editZipCode}
                                      name={"zipCode"}
                                      label={t('profile.zipCode')}
                                      onChange={data => setAddress({...address, ...data})}
                                      value={address?.zipCode}
                                      setEditValue={setEditZipCode}
                                      onSave={onSave}/>
                </li>
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <EditProfileField editValue={editCity}
                                      name={"city"}
                                      label={t('profile.city')}
                                      onChange={data => setAddress({...address, ...data})}
                                      value={address?.city}
                                      setEditValue={setEditCity}
                                      onSave={onSave}/>
                </li>
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <EditProfileField editValue={editCountry}
                                      name={"country"}
                                      label={t('profile.country')}
                                      autocomplete
                                      options={options}
                                      field={"name"}
                                      labelField={"name"}
                                      onChange={data => setAddress({...address, ...data})}
                                      value={address?.country}
                                      setEditValue={setEditCountry}
                                      onSave={onSave}/>
                </li>
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15 text-right">
                    <div className={"text-right"} style={{width: "100%"}}>
                        <i className="fa fa-save g-color-gray-dark-v5 g-color-primary--hover g-cursor-pointer g-pos-rel g-top-minus-5 g-font-size-32 g-color-primary"
                           onClick={() => setEditing(editLine1 || editZipCode || editCity || editCountry)}/>
                    </div>
                </li>
            </>}
    </>
}

function SliderField(props) {
    const [loaded, setLoaded] = useState(false);

    const {value, sliderId, onChange, name} = props;

    const slider = useRef(null);

    const handleChange = useCallback(onChange, [onChange]);

    useEffect(() => {
        if (!loaded) {
            $.HSCore.components.HSSlider.init(`#${sliderId}`, {
                change: (e, ui) => {
                    handleChange({
                        target: {
                            value: ui.value,
                            name: name
                        }
                    })
                }
            });
            setLoaded(true);
        } else if (slider) {
            $(slider.current).slider({
                change: (e, ui) => {
                    handleChange({
                        target: {
                            value: ui.value,
                            name: name
                        }
                    })
                }
            });
            /*slider.current.update({
                onChange: handleChange,
                name: name
            })*/
        }
    }, [handleChange, sliderId, name, loaded]);

    return <>
        <span className="g-color-primary"> <span id={`${props.sliderId}Amount`}>{value}</span></span>
        <div ref={slider} id={props.sliderId}
             className="u-slider-v1"
             data-result-container={`${props.sliderId}Amount`}
             data-default={value}
             data-step={props.step || 1}
             data-min={props.min || 0}
             data-max={props.max || 100}/>
    </>
}

function EditProfile(props) {
    const [profile, setProfile] = useState({...props})
    const [user, setUser] = useState({...props.user})
    const [editCompany, setEditCompany] = useState(false);
    const [editJobTitle, setEditJobTitle] = useState(false);
    const [editPhoneNumber, setEditPhoneNumber] = useState(false);
    const [editPromotion, setEditPromotion] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [editSettings, setEdiSettings] = useState(false);
    const [editDescription, setEditDescription] = useState(false);

    const [message, setMessage] = useState(null)
    const [success, setSuccess] = useState(false)

    const onSave = async (e, data, callback, type) => {
        e.preventDefault();
        try {
            overlay($, true);
            let response = await put(`/api/${type}`, data);
            setSuccess(true);
            setMessage(response.data.name + " Saved !");
            if (typeof callback === "function") {
                callback(false);
            }
        } catch (e) {
            setSuccess(false);
            let {data} = e.response;
            setMessage(data?.message || e.message || e.data?.message);
            goToTop($);
        } finally {
            overlay($, false);
        }
    };

    const onSaveProfile = async (e, data, callback) => {
        onSave(e, data, callback, 'profile');
    };

    const onSaveUser = async (e, data, callback) => {
        onSave(e, data, callback, 'user')
    };

    return <>
        <div className="tab-pane fade show active" id="nav-1-1-default-hor-left-underline--1" role="tabpanel"
             data-parent="#nav-1-1-default-hor-left-underline">
            <Alert message={message} success={success}/>
            <h2 className="h4 g-font-weight-300">{t('profile.tabs.info.title')}</h2>
            <p>{t('profile.tabs.info.subtitle')}</p>
            <ul className="list-unstyled g-mb-30">
                {/* Your ID */}
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <div className="g-pr-10">
                        <strong
                            className="d-block d-md-inline-block g-color-gray-dark-v2 g-width-200 g-pr-10">ID</strong>
                        <span className="align-top">CPD-{profile?.id}</span>
                    </div>
                    <span>
                        <i className="icon-lock g-color-gray-dark-v5 g-color-primary--hover g-cursor-pointer g-pos-rel g-top-1"/>
                      </span>
                </li>
                {/* End Your ID */}
                {/* Name */}
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <div className="g-pr-10">
                        <strong
                            className="d-block d-md-inline-block g-color-gray-dark-v2 g-width-200 g-pr-10">{t('profile.fullName')}</strong>
                        <span className="align-top">{profile?.firstName} {profile?.lastName}</span>
                    </div>
                    <span>
                        <i className="icon-lock g-color-gray-dark-v5 g-color-primary--hover g-cursor-pointer g-pos-rel g-top-1"
                           onClick={e => e.preventDefault()}/>
                      </span>
                </li>
                {/* End Name */}
                {/* Primary Email Address */}
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <div className="g-pr-10">
                        <strong
                            className="d-block d-md-inline-block g-color-gray-dark-v2 g-width-200 g-pr-10">{t('profile.username')}</strong>
                        <span className="align-top">{profile?.user.username}</span>
                    </div>
                    <span>
                        <i className="icon-lock g-color-gray-dark-v5 g-color-primary--hover g-cursor-pointer g-pos-rel g-top-1"/>
                      </span>
                </li>
                {/* End Primary Email Address */}

                {/* Phone Number */}
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <EditProfileField id={user?.id}
                                      editValue={editPhoneNumber}
                                      name={"phoneNumber"}
                                      label={t('profile.phone')}
                                      onChange={data => setUser({...user, ...data})}
                                      value={user?.phoneNumber}
                                      setEditValue={setEditPhoneNumber}
                                      onSave={onSaveUser}/>
                </li>
                {/* End Phone Number */}

                {/* Promotion */}
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <EditProfileField id={user?.id}
                                      editValue={editPromotion}
                                      name={"promotion"}
                                      label={t('profile.promotion')}
                                      onChange={data => setUser({...user, ...data})}
                                      value={user?.promotion}
                                      setEditValue={setEditPromotion}
                                      onSave={onSaveUser}/>
                </li>
                {/* End Promotion */}

                {/* Description */}
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <EditProfileField id={profile?.id}
                                      editValue={editDescription}
                                      name={"description"}
                                      label={t('profile.description')}
                                      longtext
                                      onChange={data => setProfile({...profile, ...data})}
                                      value={profile?.description}
                                      setEditValue={setEditDescription}
                                      onSave={onSaveProfile}/>
                </li>
                {/* End Description */}

                {/* Address */}
                <EditProfileAddress id={profile?.id}
                                    address={profile?.address}
                                    onSave={onSaveProfile}
                                    setEditValue={setEditAddress}
                />
                {/* End Address */}

                {/* Address */}
                <EditProfileSettings id={profile?.id}
                                     settings={profile?.settings}
                                     onSave={onSaveProfile}
                                     setEditValue={setEdiSettings}
                />
                {/* End Address */}

                {/* Company Name */}
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <EditProfileField id={profile?.id}
                                      editValue={editCompany}
                                      name={"company"}
                                      label={t('profile.company')}
                                      autocomplete
                                      path={"profile"}
                                      onChange={data => setProfile({...profile, ...data})}
                                      value={profile?.company}
                                      setEditValue={setEditCompany}
                                      onSave={onSaveProfile}/>
                </li>
                {/* End Company Name */}

                {/* Position */}
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <EditProfileField id={profile?.id}
                                      editValue={editJobTitle}
                                      name={"jobTitle"}
                                      label={t('profile.jobTitle')}
                                      autocomplete
                                      path={"profile"}
                                      onChange={data => setProfile({...profile, ...data})}
                                      value={profile?.jobTitle}
                                      setEditValue={setEditJobTitle}
                                      onSave={onSaveProfile}/>
                </li>
                {/* End Position */}
            </ul>

            <Actions {...props}
                     profile={profile}
                     editing={editCompany || editJobTitle || editPhoneNumber || editAddress || editDescription || editSettings}
                     setMessage={setMessage}
                     setSuccess={setSuccess}/>
        </div>
    </>
}

function EditProfileSettings(props) {
    const [settings, setSettings] = useState(props.settings || {});
    const [editCompanyVisible, setEditCompanyVisible] = useState(false);
    const [editAvailableForJob, setEditAvailableForJob] = useState(false);
    const [editAvailableForMission, setEditAvailableForMission] = useState(false);
    const [editing, setEditing] = useState(false);
    const [draft, setDraft] = useState(false);

    const formatSettings = (opts) => {
        return `${t('profile.settings.companyVisible')} : ${opts.companyVisible === true ? t('Yes') : t('No')}, 
                ${t('profile.settings.availableForJob')} : ${opts.availableForJob === true ? t('Yes') : t('No')}, 
                ${t('profile.settings.availableForMission')} : ${opts.availableForMission === true ? t('Yes') : t('No')}
                `
    }

    const onSave = (e, data, callback) => {
        callback(false);
        setDraft(true)
    }

    useEffect(() => {
        setEditing(editCompanyVisible || editAvailableForJob || editAvailableForMission);
    }, [editCompanyVisible, editAvailableForJob, editAvailableForMission]);

    useEffect(() => {
        props.setEditValue(editing)
        if (!editing && draft) {
            console.log('Need save !!!');
            setDraft(false);
            props.onSave({
                preventDefault: () => {
                }
            }, {id: props.id, settings}, props.setEditValue);
        }
    }, [editing, draft, settings, props]);

    return <>
        {!editing ?
            <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                <div className="g-pr-10">
                    <strong
                        className="d-block d-md-inline-block g-color-gray-dark-v2 g-width-200 g-pr-10">{t('profile.visibility')}</strong>
                    <span className="align-top">{formatSettings(settings)} </span>
                </div>
                <span>
                        <i className="icon-pencil g-color-gray-dark-v5 g-color-primary--hover g-cursor-pointer g-pos-rel g-top-1"
                           onClick={e => setEditing(true)}/>
                      </span>
            </li> :
            <>
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <EditProfileField editValue={editCompanyVisible}
                                      name={"companyVisible"}
                                      checkbox={true}
                                      label={t('profile.settings.companyVisible')}
                                      onChange={data => setSettings({...settings, ...data})}
                                      value={settings?.companyVisible}
                                      setEditValue={setEditCompanyVisible}
                                      onSave={onSave}/>
                </li>
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <EditProfileField editValue={editAvailableForJob}
                                      name={"availableForJob"}
                                      checkbox={true}
                                      label={t('profile.settings.availableForJob')}
                                      onChange={data => setSettings({...settings, ...data})}
                                      value={settings?.availableForJob}
                                      setEditValue={setEditAvailableForJob}
                                      onSave={onSave}/>
                </li>
                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15">
                    <EditProfileField editValue={editAvailableForMission}
                                      name={"availableForMission"}
                                      checkbox={true}
                                      label={t('profile.settings.availableForMission')}
                                      onChange={data => setSettings({...settings, ...data})}
                                      value={settings?.availableForMission}
                                      setEditValue={setEditAvailableForMission}
                                      onSave={onSave}/>
                </li>

                <li className="d-flex align-items-center justify-content-between g-brd-bottom g-brd-gray-light-v4 g-py-15 text-right">
                    <div className={"text-right"} style={{width: "100%"}}>
                        <i className="fa fa-save g-color-gray-dark-v5 g-color-primary--hover g-cursor-pointer g-pos-rel g-top-minus-5 g-font-size-32 g-color-primary"
                           onClick={() => setEditing(editCompanyVisible || editAvailableForJob || editAvailableForMission)}/>
                    </div>
                </li>
            </>}
    </>
}


function SecuritySettings(props) {
    //const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [message, setMessage] = useState(null)
    const [success, setSuccess] = useState(false)

    const onSave = async (data) => {
        if (/*currentPassword.trim() === '' || */password.trim() === '' || confirmPassword.trim() === '') {
            setSuccess(false);
            setMessage(t('errors.allFieldRequired'));
            return;
        }
        try {
            overlay($, true);
            let response = await post('/api/auth/updatePassword', {/*currentPassword, */password, confirmPassword});
            storeToken(response.headers);
            setSuccess(true);
            setMessage("Password updated !!");
            //setCurrentPassword('');
            setPassword('');
        } catch (e) {
            setSuccess(false);
            let {data} = e.response;
            setMessage(data?.message || e.message || e.data?.message);
        } finally {
            overlay($, false);
        }

    }
    return <>
        <div className="tab-pane fade" id="nav-1-1-default-hor-left-underline--2" role="tabpanel"
             data-parent="#nav-1-1-default-hor-left-underline">
            <h2 className="h4 g-font-weight-300">{t('profile.tabs.security.title')}</h2>
            <p className="g-mb-25"><Nl2Br value={t('profile.tabs.security.subtitle')}/></p>
            <Alert message={message} success={success}/>
            <form>
                {/* New Password */}
                <div className="form-group row g-mb-25">
                    <label
                        className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('profile.tabs.security.password')}</label>
                    <div className="col-sm-9">
                        <div className="input-group g-brd-primary--focus">
                            <input className="form-control form-control-md border-right-0 rounded-0 g-py-13 pr-0"
                                   type="password"
                                   value={password}
                                   onChange={e => setPassword(e.target.value)}
                                   autoComplete={"new-password"}
                                   placeholder={t('profile.tabs.security.password')}/>
                            <div className="input-group-append">
                                    <span className="input-group-text g-bg-white g-color-gray-light-v1 rounded-0"><i
                                        className="icon-lock"/></span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End New Password */}

                {/* Verify Password */}
                <div className="form-group row g-mb-25">
                    <label
                        className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('profile.tabs.security.confirmPassword')}</label>
                    <div className="col-sm-9">
                        <div className="input-group g-brd-primary--focus">
                            <input className="form-control form-control-md border-right-0 rounded-0 g-py-13 pr-0"
                                   type="password"
                                   value={confirmPassword}
                                   onChange={e => setConfirmPassword(e.target.value)}
                                   autoComplete={"new-password"}
                                   placeholder={t('profile.tabs.security.confirmPassword')}/>
                            <div className="input-group-append">
                                    <span className="input-group-text g-bg-white g-color-gray-light-v1 rounded-0"><i
                                        className="icon-lock"/></span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Verify Password */}

                <hr className="g-brd-gray-light-v4 g-my-25"/>

                <Actions {...props}
                         editing={false}
                         onSave={onSave}
                         setMessage={setMessage}
                         setSuccess={setSuccess}/>
            </form>
        </div>
    </>
}

function ItemViewer(props) {

    const [message, setMessage] = useState(null);
    const [success, setSuccess] = useState(false);

    const onSave = async (e) => {
        e.preventDefault();
        if (!props.validate()) {
            setSuccess(false);
            setMessage(t('errors.allFieldRequired'));
            return;
        }
        try {
            overlay($, true);
            const id = props.item.id;
            let isInsert = id === undefined || id === '';
            let func = isInsert ? post : put;
            let response = await func(`/api/${props.path}`, props.item);
            if (!isInsert) {
                props.setItem(response.data);
                setSuccess(true);
                setMessage(`${props.item.title} updated !!`);
            } else {
                props.initialize();
            }
            if (props.onNew && isInsert) {
                props.onNew(response.data);
            }
        } catch (e) {
            setSuccess(false);
            let data = e.response?.data;
            setMessage(data?.message || e.message || e.data?.message);
        } finally {
            overlay($, false);
        }
    }

    const handleDelete = async (e) => {
        e.preventDefault();
        try {
            let confirm = window.confirm(t('You really want to delete this item?'));
            if (confirm) {
                overlay($, true);
                let response = await remove(`/api/${props.path}/${props.item.id}`);
                setSuccess(true);
                setMessage(`${props.item.title} : Experience deleted !!`);
                props.onDelete(response.data);
            }
        } catch (e) {
            setSuccess(false);
            let {data} = e.response;
            setMessage(data?.message || e.message || e.data?.message);
        } finally {
            overlay($, false);
        }
    }

    return <>
        <Alert message={message} success={success}/>
        <form onSubmit={onSave}>
            {props.children}
            <div className="text-sm-right">
                {props.item.id &&
                <button type={"button"} className={"btn u-btn-outline-red g-mr-10"}
                        onClick={handleDelete}>{t('Delete')}</button>}
                <button type={"submit"} className={"btn u-btn-primary rounded-0"}>{t('Save')}</button>
            </div>
            <hr className="g-brd-gray-light-v4 g-my-25"/>
        </form>
    </>
}

function ItemSettings(props) {
    const emptyItem = props.emptyItem;
    const [items, setItems] = useState(props.items || []);
    const [newItem, setNewItem] = useState(emptyItem);

    const onNew = (item) => {
        items.push(item);
        setNewItem(Object.assign({}, emptyItem))
    }

    const onDelete = (item) => {
        let newItems = items.filter(s => s.id !== item.id);
        setItems(newItems);
    }

    const ItemView = function (args) {
        return props.itemView({...args});
    }

    return <>
        <div className="tab-pane fade" id={props.tabId}
             role="tabpanel"
             data-parent="#nav-1-1-default-hor-left-underline">
            <h2 className="h4 g-font-weight-300">{props.title}</h2>
            <p className="g-mb-25"><Nl2Br value={props.subtitle}/></p>
            {items?.map((item, index) => {
                return <ItemView key={index}  {...props} item={item} onDelete={onDelete}/>;
            })}
            <ItemView key={'-1'}  {...props} item={newItem} onNew={onNew}/>
        </div>
    </>
}

function SkillView(props) {

    const [item, setItem] = useState(props?.item);

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setItem({...item, [name]: value});
    }

    const initialize = () => {
        setItem({
            profile: item.profile,
            title: '',
            subtitle: '',
            value: '',
            domain: ''
        });
    }

    const validate = () => {
        return item?.title?.trim().length > 0;
    }

    return <ItemViewer {...props}
                       path={"skill"}
                       setItem={setItem}
                       initialize={initialize}
                       item={item}
                       validate={validate}>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('skill.title')}</label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"skill-title" + (item.id ? ("-" + item.id) : "")}
                             name={"title"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('skill.title')}
                             path={"param"}
                             value={item?.title}
                             field={"value"}
                             select={"value,label"}
                             queryFilter={`type=SKILL`}
                             labelField={"label"}/>
                <small
                    className="form-text text-muted g-font-size-default g-mt-10">{t('skill.titleInfo')}</small>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('skill.subtitle')}</label>
            <div className="col-sm-9">
                <div className="input-group g-brd-primary--focus">
                        <textarea className="form-control form-control-md rounded-0 g-py-13 pr-0"
                                  value={item?.subtitle}
                                  rows={5}
                                  name={"subtitle"}
                                  onChange={handleChange}
                                  placeholder={t('skill.subtitle')}/>
                </div>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('skill.value')}</label>
            <div className="col-sm-9">
                <SliderField sliderId={`skill-level-${item?.id}`}
                             label={t('skill.value')}
                             value={item?.value}
                             item={item}
                             name={"value"}
                             min={0}
                             max={100}
                             step={1}
                             onChange={handleChange}/>
            </div>
        </div>
    </ItemViewer>
}

function SkillSettings(props) {
    const emptyItem = {profile: {id: props.id}, title: '', subtitle: '', value: '', domain: ''};
    return <ItemSettings title={t('profile.tabs.skill.title')}
                         subtitle={t('profile.tabs.skill.subtitle')}
                         tabId={"nav-1-1-default-hor-left-underline--3"}
                         items={props.skills}
                         itemView={SkillView}
                         emptyItem={emptyItem}/>
}

function ExperienceView(props) {

    const [item, setItem] = useState(props?.item);
    const [inProgress, setInProgress] = useState(props?.item?.status === 'En Cours')

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setItem({...item, [name]: value});
    }

    const onDateChanged = (e) => {
        let date = moment(e.target.value, t('dateFormat'));
        let dateValue = date.format(YYYY_MM_DD);
        console.log('dateValue', dateValue);
        if (e.target.name === 'startDate') {
            setItem({...item, startDate: dateValue});
        } else if (e.target.name === 'endDate') {
            setItem({...item, endDate: dateValue});
        }
    }
    const initialize = () => {
        setItem({
            profile: item.profile,
            title: '',
            company: '',
            sector: '',
            domain: '',
            address: '',
            description: '',
            status: '',
            startDate: '',
            endDate: null
        });
    }

    const validate = () => {
        return item?.title?.trim().length > 0;
    }

    return <ItemViewer {...props}
                       path={"experience"}
                       setItem={setItem}
                       initialize={initialize}
                       item={item}
                       validate={validate}>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('experience.company')}</label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"experience-company" + (item.id ? ("-" + item.id) : "")}
                             name={"company"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('experience.company')}
                             path={"param"}
                             value={item?.company}
                             field={"value"}
                             select={"value,label"}
                             queryFilter={`type=COMPANY`}
                             labelField={"label"}/>
                <small
                    className="form-text text-muted g-font-size-default g-mt-10">{t('experience.companyInfo')}</small>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('experience.sector')}</label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"experience-sector" + (item.id ? ("-" + item.id) : "")}
                             name={"sector"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('experience.sector')}
                             path={"param"}
                             value={item?.sector}
                             field={"value"}
                             freeSolo={false}
                             select={"value,label"}
                             queryFilter={`type=SECTOR`}
                             labelField={"label"}/>
                <small
                    className="form-text text-muted g-font-size-default g-mt-10">{t('experience.sectorInfo')}</small>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">
                {t('experience.title')}
            </label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"experience-title" + (item.id ? ("-" + item.id) : "")}
                             name={"title"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('experience.title')}
                             path={"param"}
                             value={item?.title}
                             field={"value"}
                             select={"value,label"}
                             queryFilter={`type=JOB_TITLE`}
                             labelField={"label"}/>
                <small
                    className="form-text text-muted g-font-size-default g-mt-10">{t('experience.titleInfo')}</small>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('experience.domain')}</label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"experience-domain" + (item.id ? ("-" + item.id) : "")}
                             name={"domain"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('experience.domain')}
                             path={"param"}
                             value={item?.domain}
                             field={"value"}
                             select={"value,label"}
                             queryFilter={`type=DOMAIN`}
                             labelField={"label"}/>
                <small
                    className="form-text text-muted g-font-size-default g-mt-10">{t('experience.domainInfo')}</small>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('experience.address')}</label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"experience-address" + (item.id ? ("-" + item.id) : "")}
                             name={"address"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('experience.address')}
                             path={"param"}
                             value={item?.address}
                             field={"value"}
                             select={"value,label"}
                             queryFilter={`type=ADDRESS`}
                             labelField={"label"}/>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('experience.description')}</label>
            <div className="col-sm-9">
                <div className="input-group g-brd-primary--focus">
                                <textarea className="form-control form-control-md rounded-0 g-py-13 pr-0"
                                          value={item?.description}
                                          name={"description"}
                                          rows={5}
                                          onChange={handleChange}
                                          placeholder={t('experience.description')}/>
                </div>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <div className={"col-sm-3"}/>
            <label className="form-check-inline u-check g-pl-25">
                <input className="g-hidden-xs-up"
                       type="checkbox"
                       checked={inProgress}
                       value={inProgress}
                       name={"status"}
                       onChange={(e) => {
                           setInProgress(e.target.checked);
                           if (e.target.checked) {
                               setItem({...item, status: 'En Cours'});
                           } else {
                               setItem({...item, status: '', endDate: null});
                           }
                       }}/>
                <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                    <i className="g-brd-primary--checked g-bg-white g-bg-primary--checked g-transition-0_2 g-transition--ease-in g-rounded-2 fa"
                       data-check-icon=""/>
                </div>
                {t('In progress')}
            </label>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('experience.period')}</label>
            <div className="col-sm-9">
                {!inProgress && <DateRange label={t('experience.startDate')}
                                           fromId={'datepickerFrom-formation-' + item.id}
                                           toId={'datepickerTo-formation-' + item.id}
                                           formLabel={t('experience.startDate')}
                                           toLabel={t('experience.endDate')}
                                           startDate={item?.startDate}
                                           endDate={item?.endDate}
                                           readOnly={false}
                                           range={true}
                                           onChange={onDateChanged}/>}

                {inProgress && <DateRange label={t('experience.period')}
                                          fromId={'datepickerFrom-formation-' + item.id}
                                          toId={'datepickerTo-formation-' + item.id}
                                          formLabel={t('experience.startDate')}
                                          toLabel={t('experience.endDate')}
                                          startDate={item?.startDate}
                                          endDate={item?.endDate}
                                          readOnly={false}
                                          range={false}
                                          onChange={onDateChanged}/>}
            </div>
        </div>
    </ItemViewer>
}

function ExperienceSettings(props) {
    const emptyItem = {
        profile: {id: props.id},
        title: '',
        company: '',
        sector: '',
        domain: '',
        address: '',
        description: '',
        startDate: '',
        endDate: '',
        status: ''
    };
    return <ItemSettings title={t('profile.tabs.experience.title')}
                         subtitle={t('profile.tabs.experience.subtitle')}
                         tabId={"nav-1-1-default-hor-left-underline--4"}
                         items={props.experiences}
                         itemView={ExperienceView} emptyItem={emptyItem}/>
}

function FormationView(props) {

    const [item, setItem] = useState(props?.item);
    const [inProgress, setInProgress] = useState(props?.item?.status === 'En Cours')

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setItem({...item, [name]: value});
    }

    const onDateChanged = (e) => {
        let date = moment(e.target.value, t('dateFormat'));
        let dateValue = date.format(YYYY_MM_DD);
        if (e.target.name === 'startDate') {
            setItem({...item, startDate: dateValue});
        } else if (e.target.name === 'endDate') {
            setItem({...item, endDate: dateValue});
        }
    }

    const initialize = () => {
        setItem({
            profile: {id: props.id},
            institution: '',
            degree: '',
            domain: '',
            address: '',
            description: '',
            startDate: '',
            endDate: null,
            status: ''
        });
    }

    const validate = () => {
        return item?.institution?.trim().length > 0;
    }

    return <ItemViewer {...props}
                       path={"formation"}
                       setItem={setItem}
                       initialize={initialize}
                       item={item}
                       validate={validate}>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('formation.institution')}</label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"formation-title" + (item.id ? ("-" + item.id) : "")}
                             name={"institution"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('formation.institution')}
                             path={"param"}
                             value={item?.institution}
                             field={"value"}
                             select={"value,label"}
                             queryFilter={`type=INSTITUTION`}
                             labelField={"label"}/>
                <small
                    className="form-text text-muted g-font-size-default g-mt-10">{t('formation.institutionInfo')}</small>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('formation.domain')}</label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"formation-domain" + (item.id ? ("-" + item.id) : "")}
                             name={"domain"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('formation.domain')}
                             path={"param"}
                             value={item?.domain}
                             field={"value"}
                             select={"value,label"}
                             queryFilter={`type=DOMAIN`}
                             labelField={"label"}/>
                <small
                    className="form-text text-muted g-font-size-default g-mt-10">{t('formation.domainInfo')}</small>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('formation.degree')}</label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"formation-degree" + (item.id ? ("-" + item.id) : "")}
                             name={"degree"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('formation.degree')}
                             path={"param"}
                             value={item?.degree}
                             field={"value"}
                             select={"value,label"}
                             queryFilter={`type=DEGREE`}
                             labelField={"label"}/>
                <small
                    className="form-text text-muted g-font-size-default g-mt-10">{t('formation.degreeInfo')}</small>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('formation.address')}</label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"formation-address" + (item.id ? ("-" + item.id) : "")}
                             name={"address"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('formation.address')}
                             path={"param"}
                             value={item?.address}
                             field={"value"}
                             select={"value,label"}
                             queryFilter={`type=ADDRESS`}
                             labelField={"label"}/>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('formation.description')}</label>
            <div className="col-sm-9">
                <div className="input-group g-brd-primary--focus">
                    <textarea className="form-control form-control-md rounded-0 g-py-13 pr-0"
                              value={item?.description}
                              rows={5}
                              name={"description"}
                              onChange={handleChange}
                              placeholder={t('formation.description')}/>
                </div>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <div className={"col-sm-3"}/>
            <label className="form-check-inline u-check g-pl-25">
                <input className="g-hidden-xs-up"
                       type="checkbox"
                       checked={inProgress}
                       value={inProgress}
                       name={"status"}
                       onChange={(e) => {
                           setInProgress(e.target.checked);
                           if (e.target.checked) {
                               setItem({...item, status: 'En Cours'});
                           } else {
                               setItem({...item, status: '', endDate: null});
                           }
                       }}/>
                <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                    <i className="g-brd-primary--checked g-bg-white g-bg-primary--checked g-transition-0_2 g-transition--ease-in g-rounded-2 fa"
                       data-check-icon=""/>
                </div>
                {t('In progress')}
            </label>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('formation.period')}</label>
            <div className="col-sm-9">
                {!inProgress && <DateRange label={t('formation.startDate')}
                                           fromId={'datepickerFrom-formation-range-' + item.id}
                                           toId={'datepickerTo-formation-range-' + item.id}
                                           formLabel={t('formation.startDate')}
                                           toLabel={t('formation.endDate')}
                                           startDate={item?.startDate}
                                           endDate={item?.endDate}
                                           readOnly={false}
                                           range={true}
                                           onChange={onDateChanged}/>}

                {inProgress && <DateRange label={t('formation.period')}
                                          fromId={'datepickerFrom-formation-' + item.id}
                                          toId={'datepickerTo-formation-' + item.id}
                                          formLabel={t('formation.startDate')}
                                          toLabel={t('formation.endDate')}
                                          startDate={item?.startDate}
                                          endDate={item?.endDate}
                                          readOnly={false}
                                          range={false}
                                          onChange={onDateChanged}/>}
            </div>
        </div>
    </ItemViewer>
}

function FormationSettings(props) {
    const emptyItem = {
        profile: {id: props.id},
        institution: '',
        degree: '',
        domain: '',
        address: '',
        description: '',
        startDate: '',
        endDate: '',
        status: ''
    };
    return <ItemSettings title={t('profile.tabs.formation.title')}
                         subtitle={t('profile.tabs.formation.subtitle')}
                         tabId={"nav-1-1-default-hor-left-underline--5"}
                         items={props.formations}
                         itemView={FormationView}
                         emptyItem={emptyItem}/>
}

function HobbyView(props) {

    const [item, setItem] = useState(props?.item);

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setItem({...item, [name]: value});
    }

    const initialize = () => {
        setItem({
            profile: item.profile,
            title: '',
            description: ''
        });
    }

    const validate = () => {
        return item?.title?.trim().length > 0;
    }

    return <ItemViewer {...props}
                       path={"hobby"}
                       setItem={setItem}
                       initialize={initialize}
                       item={item}
                       validate={validate}>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('hobby.title')}</label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"hobby-title" + (item.id ? ("-" + item.id) : "")}
                             name={"title"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('hobby.title')}
                             path={"param"}
                             value={item?.title}
                             field={"value"}
                             select={"value,label"}
                             queryFilter={`type=HOBBY`}
                             labelField={"label"}/>
                <small
                    className="form-text text-muted g-font-size-default g-mt-10">{t('hobby.titleInfo')}</small>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('hobby.description')}</label>
            <div className="col-sm-9">
                <div className="input-group g-brd-primary--focus">
                        <textarea className="form-control form-control-md rounded-0 g-py-13 pr-0"
                                  value={item?.description}
                                  name={"description"}
                                  rows={5}
                                  onChange={handleChange}
                                  placeholder={t('hobby.description')}/>
                </div>
            </div>
        </div>
    </ItemViewer>
}

function HobbySettings(props) {
    const emptyItem = {profile: {id: props.id}, title: '', description: ''};
    return <ItemSettings title={t('profile.tabs.hobby.title')}
                         subtitle={t('profile.tabs.hobby.subtitle')}
                         tabId={"nav-1-1-default-hor-left-underline--6"}
                         items={props.hobbies}
                         itemView={HobbyView}
                         emptyItem={emptyItem}/>
}

function LanguageView(props) {

    const [item, setItem] = useState(props?.item);

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setItem({...item, [name]: value});
    }

    const initialize = () => {
        setItem({
            profile: item.profile,
            title: '',
            level: '',
            value: '',
        });
    }

    const validate = () => {
        return item?.title?.trim().length > 0;
    }

    return <ItemViewer {...props}
                       path={"language"}
                       setItem={setItem}
                       initialize={initialize}
                       item={item}
                       validate={validate}>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('language.title')}</label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"language-title" + (item.id ? ("-" + item.id) : "")}
                             name={"title"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('language.title')}
                             path={"param"}
                             value={item?.title}
                             field={"value"}
                             select={"value,label"}
                             queryFilter={`type=LANGUAGE`}
                             labelField={"label"}/>
                <small
                    className="form-text text-muted g-font-size-default g-mt-10">{t('language.titleInfo')}</small>
            </div>
        </div>
        <div className="form-group row g-mb-55">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('language.level')}</label>
            <div className="col-sm-9">
                <FieldSelect fieldId={"language-level" + (item.id ? ("-" + item.id) : "")}
                             name={"level"}
                             addButton={t('Add')}
                             onChange={handleChange}
                             placeholder={t('language.level')}
                             path={"param"}
                             value={item?.level}
                             field={"value"}
                             select={"value,label"}
                             queryFilter={`type=LANGUAGE_LEVEL`}
                             labelField={"label"}/>
            </div>
        </div>
        <div className="form-group row g-mb-25">
            <label
                className="col-sm-3 col-form-label g-color-gray-dark-v2 g-font-weight-700 text-sm-right g-mb-10">{t('language.value')}</label>
            <div className="col-sm-9">
                <SliderField sliderId={`language-value-${item?.id}`}
                             label={t('language.value')}
                             value={item?.value}
                             name={"value"}
                             min={0}
                             max={100}
                             step={1}
                             onChange={handleChange}/>
            </div>
        </div>
    </ItemViewer>
}

function LanguageSettings(props) {
    const emptyItem = {profile: {id: props.id}, title: '', level: '', value: ''};
    return <ItemSettings title={t('profile.tabs.language.title')}
                         subtitle={t('profile.tabs.language.subtitle')}
                         tabId={"nav-1-1-default-hor-left-underline--7"}
                         items={props.languages}
                         itemView={LanguageView}
                         emptyItem={emptyItem}/>
}

function UserDetailsEdit(props) {
    //const [loaded, setLoaded] = useState(false);
    return <>
        {/* Nav tabs */}
        <ul className="nav nav-justified u-nav-v1-1 u-nav-primary g-brd-bottom--md g-brd-bottom-2 g-brd-primary g-mb-20"
            role="tablist" data-target="nav-1-1-default-hor-left-underline" data-tabs-mobile-type="slide-up-down"
            data-btn-classes="btn btn-md btn-block rounded-0 u-btn-outline-primary g-mb-20">
            <li className="nav-item g-mr-5 g-mt-5--sm g-mt-minus-5--lg">
                <a className="nav-link g-py-10 active" data-toggle="tab" href={"#nav-1-1-default-hor-left-underline--1"}
                   role="tab">{t('profile.tabs.info.value')}</a>
            </li>
            <li className="nav-item g-mr-5 g-mt-5--sm g-mt-minus-5--lg">
                <a className="nav-link g-py-10" data-toggle="tab" href={"#nav-1-1-default-hor-left-underline--3"}
                   role="tab">{t('profile.tabs.skill.value')}</a>
            </li>
            <li className="nav-item g-mr-5 g-mt-5--sm g-mt-minus-5--lg">
                <a className="nav-link g-py-10" data-toggle="tab" href={"#nav-1-1-default-hor-left-underline--4"}
                   role="tab">{t('profile.tabs.experience.value')}</a>
            </li>
            <li className="nav-item g-mr-5 g-mt-5--sm g-mt-minus-5--lg">
                <a className="nav-link g-py-10" data-toggle="tab" href={"#nav-1-1-default-hor-left-underline--5"}
                   role="tab">{t('profile.tabs.formation.value')}</a>
            </li>
            <li className="nav-item g-mr-5 g-mt-5--sm g-mt-minus-5--lg">
                <a className="nav-link g-py-10" data-toggle="tab" href={"#nav-1-1-default-hor-left-underline--7"}
                   role="tab">{t('profile.tabs.language.value')}</a>
            </li>
            <li className="nav-item g-mr-5 g-mt-5--sm g-mt-minus-5--lg">
                <a className="nav-link g-py-10" data-toggle="tab" href={"#nav-1-1-default-hor-left-underline--6"}
                   role="tab">{t('profile.tabs.hobby.value')}</a>
            </li>
            <li className="nav-item g-mr-5 g-mt-5--sm g-mt-minus-5--lg">
                <a className="nav-link g-py-10" data-toggle="tab" href={"#nav-1-1-default-hor-left-underline--2"}
                   role="tab">{t('profile.tabs.security.value')}</a>
            </li>
        </ul>
        {/* End Nav tabs */}

        {/* Tab panes */}
        <div id="nav-1-1-default-hor-left-underline" className="tab-content">
            {/* Edit Profile */}
            <EditProfile {...props} />
            {/* End Edit Profile */}

            {/* Security Settings */}
            <SecuritySettings {...props}/>
            {/* End Security Settings */}

            {/* Skills Settings */}
            {<SkillSettings {...props}/>}
            {/* End Skills Settings */}

            {/* Experience Settings */}
            {<ExperienceSettings {...props}/>}
            {/* End Experience Settings */}

            {/* Formation Settings */}
            {<FormationSettings {...props}/>}
            {/* End Formation Settings */}

            {/* Language Settings */}
            {<LanguageSettings {...props}/>}
            {/* End Language Settings */}

            {/* Hobby Settings */}
            {<HobbySettings {...props}/>}
            {/* End Hobby Settings */}
        </div>
        {/* End Tab panes */}
    </>
}

function UserDetails(props) {

    useEffect(() => {
        $.HSCore.components.HSTabs.destroy('.js-tabs-mobile-control');
        $.HSCore.components.HSTabs.init('[role="tablist"]');
    }, [props.editing]);

    return props.editing ?
        <UserDetailsEdit {...props} onSave={(data) => {
            if (typeof props.onEdit === "function") {
                props.onEdit(false);
            }
        }}/>
        :
        <UserDetailsView {...props} onEdit={() => {
            if (typeof props.onEdit === "function") {
                props.onEdit(true);
            }
        }}/>
}

function ProgressItem(props) {
    const history = useHistory();
    const {id, value, title, subtitle, itemKey, bgColor} = props;
    const style = {
        width: `${value}%`,
        backgroundColor: bgColor
    };

    const progressBar = useRef(null);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            setTimeout(function () { // important in this case
                $.HSCore.components.HSProgressBar.init('.js-hr-progress-bar-' + id, {
                    direction: 'horizontal',
                    indicatorSelector: '.js-hr-progress-bar-indicator' + id
                });
            }, 1);
            setLoaded(true);
        }
    }, [loaded, value, id])
    return <>
        <div className="g-mb-20">
            <h6 className="g-mb-10"
                style={{cursor: "pointer"}}
                onClick={e => history.push(`/annuaire?${itemKey}=${title}`)}>{title} <span
                className="float-right g-ml-10">{value}%</span></h6>
            <div ref={progressBar}
                 className={`js-hr-progress-bar-${props.id} progress g-bg-black-opacity-0_1 rounded-0 g-mb-5`}>
                <div className={`js-hr-progress-bar-indicator-${props.id} progress-bar u-progress-bar--xs`}
                     role="progressbar"
                     style={style}
                     aria-valuenow={value}
                     aria-valuemin="0"
                     aria-valuemax="100"/>
            </div>
            <small className="g-font-size-12" title={subtitle} data-tooltip={subtitle}>{truncate(subtitle, 50)}</small>
        </div>
    </>
}

function ProgressItems(props) {
    const {items, itemKey} = props;
    const scrollbar = useRef(null);

    useEffect(() => {
        $.HSCore.components.HSScrollBar.init($('.js-scrollbar-item'));
    });

    return <>
        {/* User Skills */}
        <div ref={scrollbar}
             className="js-scrollbar-item card-block u-info-v1-1 g-bg-white-gradient-v1--after g-height-300 g-pa-0">
            {items.map((item) => {
                return <ProgressItem key={item.id} {...item} itemKey={itemKey}/>
            })}
        </div>
        {/* End User Skills */}
    </>
}

function TagCloudViewer(props) {
    const tags = (props.items || []).map(it => {
        let item = Object.assign({}, it);
        item.count = it.value * 1;
        item.value = it.title;
        item.color = it.bgColor;
        return item;
    });
    const m = props.min || 12;
    const M = props.max || 48;
    const sizeComputer = (x) => {
        return fontSizeComputer(x, m, M);
    }
    const customRenderer = (tag) => {
        const size = sizeComputer(tag.count);
        return (
            <span key={tag.id}
                  onClick={e => {
                      history.push(`/annuaire?${props.itemKey}=${tag.title}`);
                  }}
                  style={{
                      color: tag.color || randomColor(),
                      margin: "0 3px",
                      verticalAlign: "middle",
                      display: "inline-block",
                      cursor: "pointer",
                      fontSize: `${size}px`
                  }}>
              {t({key: tag.value})}
            </span>)
    }
    const history = useHistory();
    /*<TagCloud tags={tags}
              minSize={12}
              maxSize={32}
              onClick={e => {
                  history.push(`/annuaire?${props.itemKey}=${e.title}`);
              }}/>*/
    return <>
        <div className={"tag-cloud"}>
            {tags.map(customRenderer)}
        </div>
    </>
}

function UserBadge(props) {
    let badge = getScoreImage(props.score);
    let color = getScoreColor(props.score);
    const score = Math.round(props.score * 100)
    return <div className={"text-center"} title={"Taux de remplissage du profile"}>
        {/*<i className="icon-badge g-font-size-75--lg g-font-size-60--md g-font-size-40--sm" style={{color}}/>*/}
        <img src={badge} style={{color, width: 80}} alt={`Score ${score}`}/>
        <div className={"g-font-weight-800 d-none d-md-block"}
             style={{marginTop: '-62px', marginLeft: props.list ? '15px' : ''}}>{score}%
        </div>
    </div>
}

export function UserBlock(props) {
    const skills = props.skills || [];
    const languages = props.languages || [];

    return <>
        {/* User Block */}
        <div className="g-brd-around g-brd-gray-light-v4 g-pa-20 g-mb-40">
            <div className="row">
                <div className="col-lg-3 g-mb-40 g-mb-0--lg">
                    {props.userId && props.userId === props.user?.id ? <UserImage {...props}/> :
                        <ProfileImage {...props}/>}
                    {(!props.editing && skills.length > 0 && !props.list) &&
                    <div className="card border-0 rounded-0 g-mb-50 g-mt-30 d-none d-lg-block">
                        <div
                            className="card-header d-flex align-items-center justify-content-between g-bg-gray-light-v5 border-0 g-mb-15">
                            <h3 className="h6 mb-0">
                                <i className="icon-layers g-pos-rel g-top-1 g-mr-5"/> {t({key: 'profile.skills'})}
                            </h3>
                        </div>
                        <ProgressItems {...props}
                                       items={skills.sort((a, b) => b.value - a.value)}
                                       itemKey={"text"}/>
                    </div>}
                    {props.editing && <div className="text-center">
                        <a className="btn u-btn-primary rounded-0 g-py-12 g-px-25 g-mt-20 g-min-width-100x"
                           href={"/#"}
                           onClick={(e) => {
                               e.preventDefault();
                               props.onEdit(false);
                           }}
                        >{t({key: 'Finish'})}</a>
                    </div>}
                </div>
                <div className="col-lg-9">

                    <UserDetails {...props}/>

                    {(!props.editing && skills.length > 0) &&
                    <TagCloudViewer {...props} items={skills} itemKey={'text'} min={12}
                                    max={props.list ? 32 : 48}/>}
                    {(!props.editing && skills.length > 0 && languages.length > 0) && <hr/>}
                    {(!props.editing && languages.length > 0) &&
                    <TagCloudViewer {...props} items={languages} itemKey={'text'} min={12} max={32}/>}
                </div>
            </div>
        </div>
        {/* End User Block */}
    </>
}

function TimelineDate(props) {
    return <>
        {/* Timeline Date */}
        <div
            className="col-md-3 align-self-center text-md-right g-pr-40--md g-mb-20 g-mb-0--md">
            <h4 className="h5 g-font-weight-300">{props.jobTitle}</h4>
            <h5 className="h6 g-font-weight-300 mb-0">{props.from} - {props.to}</h5>
        </div>
        {/* End Timeline Date */}
    </>
}

function TimelineContent(props) {
    const history = useHistory();
    return <>
        {/* Timeline Content */}
        <div className="col-md-9 align-self-center g-orientation-left g-pl-40--md">
            {/* Timeline Dot */}
            <div className="g-hidden-sm-down u-timeline-v2__icon g-top-35">
                <i className="d-block g-width-18 g-height-18 g-bg-primary g-brd-around g-brd-4 g-brd-gray-light-v4 rounded-circle"/>
            </div>
            {/* End Timeline Dot */}

            <article className="g-pa-10--md">
                <h3 className="h3">{props.company}</h3>
                {props.sector && <h4>
                    <span onClick={goTo(history, `/annuaire?text=${props.sector}`)}
                          className={"g-font-size-16 g-cursor-pointer"}><i
                        className={"icon-trophy g-pos-rel g-top-1 g-color-gray-dark-v5 g-mr-5"}/> {props.sector}</span>
                </h4>}
                {props.address && <a href={`/annuaire?text=${props.address}`}
                                     onClick={goTo(history, `/annuaire?text=${props.address}`)}><i
                    className={"icon-location-pin g-pos-rel g-top-1 g-color-gray-dark-v5 g-mr-5"}/> {props.address}</a>}
                <p className="mb-0 g-mt-10"><Nl2Br value={props.description}/></p>
            </article>
        </div>
        {/* End Timeline Content */}
    </>
}

function TimelineItem(props) {
    return <>
        <div className="row">
            <TimelineDate jobTitle={props.jobTitle} from={props.from} to={props.to}/>

            <TimelineContent sector={props.sector}
                             domain={props.domain}
                             company={props.company}
                             address={props.address}
                             description={props.description}/>
        </div>
    </>
}

function TimelineHeader(props) {
    return <>
        <div
            className="card-header d-flex align-items-center justify-content-between g-bg-primary g-color-white border-0 g-mb-15">
            <h3 className="h6 mb-0 g-font-weight-800 g-font-size-24">
                <i className={`${props.icon} g-pos-rel g-top-1 g-mr-5`}/> {props.title}
            </h3>
            <div className="g-mb-10 g-mb-0--md">
                <span className="g-color-primary--hover g-cursor-pointer g-mr-minus-5 g-pa-5">
                    {props.canEdit && <i className="icon-plus g-font-size-12 g-color-gray-dark-v5 g-mr-5"
                                         onClick={props.onAdd}/>}
                </span>
            </div>
        </div>
    </>
}

function TimelineBody(props) {
    return <>
        {props.items.length > 0 &&
        <div className="js-scrollbar card-block u-info-v1-1 g-bg-white-gradient-v1--after g-height-350--lg g-pa-0">
            <ul className="row u-timeline-v2-wrap list-unstyled">
                {props.items.map(item => {
                    return <li key={item.id}
                               className="col-md-12 g-brd-bottom g-brd-0--md g-brd-gray-light-v4 g-pb-30 g-pb-0--md g-mb-30 g-mb-20--md">
                        <TimelineItem jobTitle={item.jobTitle}
                                      from={item.from}
                                      to={item.to}
                                      company={item.company}
                                      domain={item.domain}
                                      address={item.address}
                                      sector={item.sector}
                                      description={item.description}/>
                    </li>
                })}
            </ul>
        </div>}
    </>
}

function Experience(props) {
    //const [edit, setEdit] = useState(false);
    const items = (props.experiences || [])
        .sort((a, b) => Boolean(a.startDate) ? -a.startDate.localeCompare(b.startDate) : -1)
        .map((item) => {
            return {
                id: item.id,
                jobTitle: item.title,
                from: item.startDate ? moment(item.startDate).format("MMMM YYYY") : '',
                to: item.endDate ? moment(item.endDate).format("MMMM YYYY") : t('In progress'),
                company: item.company,
                description: item.description,
                sector: item.sector,
                address: item.address,
                domain: item.domain
            }
        });
    const onAdd = e => {
        e.preventDefault();
        console.log('Add new Experience');
    };

    return <>
        {/* Panel Header */}
        <TimelineHeader {...props} title={t('Experience')}
                        onAdd={onAdd}
                        icon={"icon-briefcase"}/>
        {/* End Panel Header */}
        {/* Timeline Content */}
        <TimelineBody {...props} items={items}/>
        {/* End Timeline Content */}
    </>
}

function Education(props) {
    const items = (props.formations || []).sort((a, b) => Boolean(a.startDate) ? -a.startDate.localeCompare(b.startDate) : -1)
        .map((item) => {
            return {
                id: item.id,
                jobTitle: item.domain,
                from: item.startDate ? moment(item.startDate).format("MMMM YYYY") : '',
                to: item.endDate ? moment(item.endDate).format("MMMM YYYY") : item.status,
                company: `${item.institution}, ${item.degree}`,
                description: item.description,
                address: item.address,
                sector: item.domain
            }
        });
    return <>
        {/* Panel Header */}
        <TimelineHeader {...props} title={t('Formation')}
                        icon={"icon-graduation"}
                        onAdd={e => {
                            e.preventDefault();
                            console.log('Add new Education');
                        }}/>
        {/* End Panel Header */}
        {/* Timeline Content */}
        <TimelineBody items={items}/>
        {/* End Timeline Content */}
    </>
}

function ProfileItem(props) {
    const items = (props.items || []);
    return <>
        <div className="card border-0 rounded-0">
            {/* Panel Header */}
            <TimelineHeader {...props} {...props} icon={"icon-directions"}/>
            {/* End Panel Header */}

            {/* Panel Body */}
            {items.length > 0 && <div
                className="js-scrollbar card-block u-info-v1-1 g-bg-white-gradient-v1--after g-height-350--lg g-pa-0">
                <ul className="list-unstyled">
                    {items.map((item, index) => {
                        return <li key={index} className="g-brd-bottom g-brd-gray-light-v4 g-py-10 g-px-15">
                            <h3>{t(item.title)}</h3>
                            <p>
                                <Nl2Br value={t(item.subtitle || '') || item.description || t(item.level || '')}/>
                            </p>
                        </li>
                    })}
                </ul>
            </div>}
            {/* End Panel Body */}
        </div>
    </>
}

function UserAccounts(props) {
    return <>
        <div className="row">
            {/* User Accounts (Option 1) */}
            <div className="col-md-12">
                <ProfileItem {...props} items={props.skills} title={t('Skills')}
                             onAdd={e => {
                                 e.preventDefault();
                                 console.log('Add new Skill');
                             }}/>
            </div>
            {/* End User Accounts (Option 1) */}

            {/* User Accounts (Option 2) */}
            <div className="col-md-6">
                {/* User Accounts */}
                <ProfileItem {...props} items={props.hobbies} title={t('Hobbies')}
                             onAdd={e => {
                                 e.preventDefault();
                                 console.log('Add new Hobby');
                             }}/>
                {/* End User Accounts */}
            </div>
            {/* End User Accounts (Option 2) */}

            {/* User Accounts (Option 2) */}
            <div className="col-md-6">
                {/* User Accounts */}
                <ProfileItem {...props} items={props.languages}
                             title={t('Languages')}
                             onAdd={e => {
                                 e.preventDefault();
                                 console.log('Add new Language');
                             }}/>
                {/* End User Accounts */}
            </div>
            {/* End User Accounts (Option 2) */}
        </div>
    </>
}

/*export function ProfileSidebar(props) {
    return <>
        <UserImage {...props}/>

        <UserNavigation {...props}/>

        <UserProgress {...props}/>
    </>
}*/

export function convertSkills(profile) {
    const skillColors = getSkillColors();

    return (profile?.skills || []).map((skill, index) => {
        return {
            ...skill, color: "g-color-primary",
            fgColor: skillColors[index].fgColor,
            bgColor: skillColors[index].bgColor
        }
    })
}

export default function ProfileView(props) {
    const [profile, setProfile] = useState({});
    const [editUserBlock, setEditUserBlock] = useState(false)

    const params = useParams();

    useEffect(() => {
        const retrieveProfile = async () => {
            let id = params.id || props.userId;
            if (id) {
                overlay($, true);
                try {
                    console.log(`/api/profile/list?user.id=${id}`);
                    let {data} = await get(`/api/profile/list?user.id=${id}`);
                    console.log('response.data', data);
                    if (data.length > 0) {
                        $.HSCore.components.HSScrollBar.destroy($('.js-scrollbar-item'));
                        setProfile(data[0]);
                    }
                } catch (e) {
                    if (e.response) {
                        let {data} = e.response;
                        console.error('error', data);
                    } else {
                        console.log('retrieveProfile:e', e);
                    }
                } finally {
                    overlay($, false);
                }
            }
        };

        retrieveProfile();
    }, [params.id, props.userId]);

    const handleEdit = (edit) => {
        if (!edit) {
            const reloadProfile = async () => {
                try {
                    overlay($, true);
                    let response = await get(`/api/profile/${profile.id}`);
                    $.HSCore.components.HSScrollBar.destroy($('.js-scrollbar-item'));
                    setProfile(response.data);
                } catch (e) {
                    let {data} = e.response;
                    console.log('data?.message', data?.message);
                    goToTop($);
                } finally {
                    overlay($, false);
                }
            }
            reloadProfile();
        }
        setEditUserBlock(edit);
    }

    const history = useHistory();
    const getGoTo = (path) => {
        return goTo(history, path)
    }
    useEffect(() => {
        initCharts($);
        goToTop($);
    });

    const onImageChanged = (e) => {
        const profileUrl = `${e.target.value[0].link}?versionId=${e.target.value[0].versionId}`;
        const user = {
            id: profile.user?.id,
            profileUrl: profileUrl
        };
        overlay($, true);
        put(`/api/user`, user)
            .then(({data}) => {
                let newProfile = Object.assign({}, profile, {user: data});
                setProfile(newProfile);
                overlay($, false);
            })
            .catch(e => {
                overlay($, false);
                let {data} = e.response;
                console.log('data?.message', data?.message);
                goToTop($);
            });
    }

    if (profile) {
        document.title = 'CPD : ' + profile.name;
    }

    return <section className="g-mb-100">
        <div className="container">
            <div className="row">
                {/* Profile Sidebar
                <div className="col-lg-1 g-mb-50 g-mb-0--lg">
                    {/*<ProfileSidebar {...props}/>
                </div>
                 End Profile Sidebar */}

                {/* Profile Content */}
                <div className="col-lg-12">
                    <UserBlock {...props} {...profile}
                               onImageChanged={onImageChanged}
                               goTo={getGoTo}
                               skills={convertSkills(profile)}
                               onEdit={handleEdit}
                               editing={editUserBlock}/>
                    {!editUserBlock && <>
                        {/* Experience Timeline */}
                        <div className="card border-0 rounded-0 g-mb-40">
                            <Experience {...profile} canEdit={false/*profile?.user?.id === props.userId*/}/>
                        </div>
                        {/* End Experience Timeline */}

                        {/* Education Timeline */}
                        <div className="card border-0 rounded-0 g-mb-40">
                            <Education {...profile} canEdit={false/*profile?.user?.id === props.userId*/}/>
                        </div>
                        {/* End Education Timeline */}

                        {/* User Accounts */}
                        <UserAccounts {...profile} canEdit={false/*profile?.user?.id === props.userId*/}/>
                        {/* End User Accounts */}
                    </>}
                </div>
                {/* End Profile Content */}
            </div>
        </div>
    </section>
}