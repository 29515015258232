import React, {useState} from "react";
import {overlay, post} from "../utils";
import Alert from "./Alert";

import t from "../i18n/translator";

const {$} = window;

const MAX_FILE_SIZE = 3 * 1024 * 1024;
export default function FileField(props) {

    const [value, setValue] = useState('');

    const handleUpload = (e) => {
        e.preventDefault();
        setValue(e.target.value);
        const formData = new FormData();
        let file = e.target.files[0];
        let fileSize = file.size;
        let currentSize = fileSize / 1024 / 1024;
        if (fileSize > MAX_FILE_SIZE) {
            if (typeof props.onError === 'function') {
                props.onError({
                    response: {
                        data: {
                            message: `${t('Maximum file size allowed is 3Mo')} (${Math.ceil(currentSize * 10) / 10.}Mo)`
                        }
                    }
                });
                setValue('');
                return;
            }
        }
        formData.append(props.name, file);
        formData.append("path", "images/cpd300");
        overlay($, true);
        post(`/api/data/upload`, formData)
            .then(response => {
                console.log('handleUpload:response', response);
                if(typeof props.onChange === 'function') {
                    props.onChange({
                        preventDefault: e.preventDefault,
                        target: {
                            value: response.data,
                            name: props.name,
                            params: props.params
                        }
                    });
                    setValue('');
                }
                if(typeof props.onSuccess === 'function') {
                    props.onSuccess({
                       response: {
                           data: {
                               message: t('Photo changed successfully')
                           }
                       }
                    });
                }
                overlay($, false);
            })
            .catch(error => {
                if (typeof props.onError === 'function') {
                    props.onError(error);
                } else {
                    console.log(error.response);
                    alert(error?.response?.data?.message);
                }
                overlay($, false);
            });
    }

    return <>
        <Alert/>
        <label className="u-file-attach-v2 g-color-gray-dark-v5 mb-0 g-cursor-pointer">
            <input id={props.fieldId}
                   name={props.name}
                   type="file"
                   value={value}
                   accept={props.accept}
                   onChange={handleUpload}/>
            <i className="icon-cloud-upload g-font-size-16 g-pos-rel g-top-2 g-mr-5"/>
            <span className="js-value">{props.label}</span>
        </label>
    </>
}